import React, { Component } from "react";
import { connect } from "react-redux";
import { close_snack_bar } from "../../../common/snackbar/action";
import { viewCartOrder } from "../action";
import Orders from "../components/Orders";
export class Controller extends Component {
  render() {
    return <Orders {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    order: store.order,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
     viewCartOrder: (token) => {
      dispatch(viewCartOrder(token));
     },  
    // delete_carditem:(cartid,productId,token) =>{
    //   dispatch(delete_carditem(cartid,productId,token,));
    //   console.log(cartid,productId);
    // }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
