import { VIEW_WISHLIST } from "./constant";
import "firebase/storage";
import axios from 'axios'; 
import { setLoader, unsetLoader } from "../../common/loader/action";
import UNIVERSAL from "../../config/config";
import { set_snack_bar, } from "../../common/snackbar/action";


export function viewAllWishlist(token) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/wishlist/{userName}", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.status) {
          dispatch({
            type: VIEW_WISHLIST,
            payload: {
              data: responseJson.result.data.wishlistItems,
              count: responseJson.result.data.length,
            },
          });
          dispatch(set_snack_bar(true, "Data View Sucessfully"));
          dispatch(unsetLoader());
        } else {
          dispatch({ type: VIEW_WISHLIST, payload: { data: [], count: 0 } });
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unsetLoader());
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function deletewishlist(itemId ,token) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/wishlist/" + itemId  + "/remove", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      },
      // body: JSON.stringify({
      //   id: attributeid,
      // }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewAllWishlist(token));
         
          dispatch(unsetLoader());
        } else {
          // alert("No Registration Found! Please Register First");
          // dispatch({ type: SET_USER, payload: [] })
          // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
// export function deletewishlist(itemId ,token) {
//   console.log(itemId,token)
//   return (dispatch) => {
//     dispatch(setLoader());
//     return fetch(UNIVERSAL.BASEURL + "user/wishlist/" + itemId  + "/remove", {
//       method: "DELETE",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: token
//       },
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           dispatch(viewAllWishlist(token));
//           dispatch(unsetLoader());
//         } else {

//         }
//         dispatch(unsetLoader());
//         dispatch(set_snack_bar(true, responseJson.message));
//       })
//       .catch((error) => {
//         dispatch(unsetLoader());
//         dispatch(set_snack_bar(true, "Check Your Internet Connection"));
//         console.error(error);
//       });
//   };
// }



