import "firebase/storage";
import { setLoader, unsetLoader } from "../../common/loader/action";
import UNIVERSAL from "../../config/config";
import { set_snack_bar } from "../../common/snackbar/action";
import { VIEW_CART_ORDERS } from "./constant";



export function viewCartOrder(token) {
  return async (dispatch) => {
    dispatch(setLoader());
    return fetch(
      UNIVERSAL.BASEURL + "user/orders",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch cart products");
        }
        return response.json();
      })
      .then((responseJson) => {
        console.log("View cart products response:", responseJson);  // Debugging log
        if (responseJson.status) {
          const cartItems = responseJson.result.data.userOrders || [];  // Ensure cartItems is an array
          dispatch({
            type: VIEW_CART_ORDERS,
            payload: {
              data: cartItems,
            },
          });
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch({ type: VIEW_CART_ORDERS, payload: { data: [], count: 0 } });
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error("Error fetching cart products:", error);  // Debugging log
        dispatch(set_snack_bar(true, "An error occurred while fetching cart products"));
        dispatch(unsetLoader());
      });
  };
}


