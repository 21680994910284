/** @format */

import React from "react";
import { useState, useEffect } from "react";
import "../Component/ShoppingcartPage.css";
import { FaStar } from "react-icons/fa";
import SHOPPINGIMAGE1 from "../../../Assets/firstslideimage1.png";
import { CiStar } from "react-icons/ci";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import Tabs from "@mui/material/Tabs";
import StarIcon from "@mui/icons-material/Star";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Footer from "../../Footer/Footer";
import { Link } from "react-router-dom";
import { Grid } from "rsuite";
import star from "../Component/Vector (2).png";
import es from "../Component/es.png";
import righticon from "../../../Assets/righticon.png";
const YellowStarIcon = () => <StarIcon htmlColor="yellow" />;
export const ShoppingcartPage = (props) => {
  // const [quantity, setQuantity] = useState(0);
  const [id, SetId] = useState("");
  const [productid, setproductid] = useState("");
  const [quantities, setQuantities] = useState([]);

  const totalItems = quantities.reduce((total, quantity) => total + quantity, 0);
  const subtotal = quantities.reduce((total, quantity, index) => {
    const price =
      props.viewshoppingcart.view_all_cart_product.products[index]
        .productDetails.regularPrice *
      (1 -
        props.viewshoppingcart.view_all_cart_product.products[index]
          .productDetails.discountPercentage / 100);
    return total + price * quantity;
  }, 0);
  const handleplusIncrement = (index) => {
    setQuantities((prevQuantities) =>
      prevQuantities.map((quantity, i) => (i === index ? quantity + 1 : quantity))
    );
  };

  const handleminusDecrement = (index) => {
    setQuantities((prevQuantities) =>
      prevQuantities.map((quantity, i) =>
        i === index && quantity > 1 ? quantity - 1 : quantity
      )
    );
  };
  const handleDelete = (cartId, productId) => {
    props.delete_carditem(cartId, productId, props.login.admin_id);
    // After deletion, update the state to remove the deleted product
    const updatedProducts = props.viewshoppingcart.view_all_cart_product.products.filter(
      (product) => product.productDetails._id !== productId
    );
    // Update quantities to match the new product list
    setQuantities(updatedProducts.map(() => 1));
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    valueone: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [valueone, setValueone] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValueone(newValue);
  };
  const labels = {
    0.5: "0.5",
    1: "1",
    1.5: "1.5",
    2: "2",
    2.5: "2.5",
    3: "3",
    3.5: "3.5",
    4: "4",
    4.5: "4.5",
    5: "5",
  };
  useEffect(() => {
    if (props.viewshoppingcart.view_all_cart_product?.products) {
      setQuantities(props.viewshoppingcart.view_all_cart_product.products.map(() => 1));
    }
  }, [props.viewshoppingcart.view_all_cart_product.products]);
  console.log(props.viewshoppingcart.view_all_cart_product);
  const [selectedCard, setSelectedCard] = useState(null);
  const handleRadioChange = (index) => {
    setSelectedCard(index);
  };
  useEffect(() => {
    props.viewCartProduct(props.login.admin_id);
    return () => { };
  }, []);



  // const deletecard =()=>{
  //   props.delete_carditem(props.products._id,props.login.admin_id)
  // }
  return (

    <div className="SHOPPINGPAGE-body-background">
      {/* <div className="group">
        <svg className="icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
        <input placeholder="Search" type="search" className="input" />
      </div> */}
      <div id="SHOPPINGPAGE_HEADING_ONE" style={{ padding: '1rem', textAlign: 'left' }}>
        <h2
          style={{
            fontWeight: '700',
            fontSize: '35px',
            margin: 0,
            paddingLeft: '23px',
            color: '#333', // Optional: Adjust color for better visibility
          }}
        >
          Shopping Cart
        </h2>
      </div>
      {/* start */}
      <div className="container-shoppingcart" style={{ display: "flex", gap: "2rem", padding: "2rem" }}>
        <div className="left-side" style={{ flex: 2 }}>
          <div className="scrollable-cards" style={{ maxHeight: "80vh", overflowY: "auto", background: '#fff', }}>
          {Array.isArray(props.viewshoppingcart.view_all_cart_product) &&
      props.viewshoppingcart.view_all_cart_product.length > 0 ? (
        props.viewshoppingcart.view_all_cart_product.map((carddata, index) => (
                <div key={carddata._id} className="card-view-data" style={{ padding: "1rem", borderBottom: "1px solid #ddd", marginBottom: "1rem" }}>
                  <div className="inside_card" >
                    <input
                      type="radio"
                      id={`card-${index}`}
                      name="card"
                      value={index}
                      checked={selectedCard === index}
                      onChange={() => handleRadioChange(index)}
                      className="radio-shoppingcart"
                    />
                    <div className='inside_cardelement'>
                      <img
                        src={carddata.productDetails.images[0]}
                        className="item-image"
                        alt={`Product Image ${index + 1}`}
                        style={{ width: "150px", height: "150px", objectFit: "cover", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          textAlign: "start",
                          padding: "0 1rem", // Added padding for better spacing within the container
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "15px",  // Font size for small screens
                              md: "16px",  // Font size for medium screens
                              xl: "18px",  // Font size for extra large screens
                            },
                            fontWeight: {
                              xs: 500,
                              sm: 550,    // Font weight for small screens
                              md: 600,    // Font weight for medium screens
                              xl: 700,    // Font weight for extra large screens
                            },
                            lineHeight: {
                              xs: '18px',
                              sm: '19px',
                              md: '20px',  // Line height for medium screens
                              xl: '22px',  // Line height for extra large screens
                            },
                            textAlign: 'left',
                            padding: '0px',
                          }}
                        >
                          {carddata.productDetails.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 600,
                            textAlign: 'left', padding: '0px', color: '#ff70a6'
                          }}
                        >
                          {carddata.productDetails.stockStatus}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 600,
                            textAlign: 'left', padding: '0px', color: '#ff70a6'
                          }}
                        >
                          {/* Color: {carddata.productDetails.colors.colorName} */}
                        </Typography>
                        <Grid
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0rem",
                          }}
                        >
                          <Typography style={{ color: "gray", fontSize: "15px", padding: '0px' }}>
                            MRP: <del>₹{carddata.productDetails.regularPrice}</del>
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "#000",
                              padding: '4px'
                            }}
                          >
                            ₹
                            {
                              (carddata.productDetails.regularPrice *
                                (1 - carddata.productDetails.discountPercentage / 100)).toFixed(2)
                            }
                          </Typography>
                          <div
                            style={{
                              height: '1rem',
                              width: '1px',
                              backgroundColor: '#ddd',
                              margin: '0 5px',
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "#FF70A6",
                              padding: '0px'

                            }}
                          >
                            {carddata.productDetails.discountPercentage}% off
                          </Typography>

                        </Grid>

                        <Grid
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.3rem",
                            padding: '0',
                            margin: '0'
                          }}
                        >
                          {/* Display stars based on the rating */}
                          {[...Array(4)].map((_, i) => (
                            <img
                              key={i}
                              src={star}
                              style={{ width: "15px", height: "15px" }}
                              alt="Star"
                            />
                          ))}
                          <img
                            src={es}
                            style={{ width: "15px", height: "15px" }}
                            alt="Extra Star"
                          />

                          {/* Display the rating value and total number of ratings */}
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#000",
                              padding: '5px'
                            }}
                          >
                            4.0
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              color: "gray",
                              padding: '0px'
                            }}
                          >
                            | (1450)
                          </Typography>
                        </Grid>


                        <Grid
                          className="counter-container"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.1rem",
                            marginTop: "0.5rem",
                          }}
                        >
                          <div

                            style={{
                              display: "flex",
                              alignItems: "center",

                            }}
                          >
                            <button
                              className="counter-button"
                              onClick={() => handleminusDecrement(index)}
                              style={{
                                padding: "0.6rem",
                                color: "#fff",
                                backgroundColor: "#ff70a6",
                                border: "1px solid #ddd",
                                borderRight: "none",
                                borderRadius: "4px 0 0 4px",
                              }}
                            >
                              -
                            </button>
                            <div
                              className="counter-display"
                              style={{
                                padding: "0.5rem 1rem",
                                color: "#fff",
                                backgroundColor: "#ff70a6",
                                borderTop: "1px solid #ddd",
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              QTY: {quantities[index]}
                            </div>
                            <button
                              className="counter-button"
                              onClick={() => handleplusIncrement(index)}
                              style={{
                                padding: "0.6rem",
                                color: "#fff",
                                backgroundColor: "#ff70a6",
                                border: "1px solid #ddd",
                                borderLeft: "none",
                                borderRadius: "0 4px 4px 0",
                              }}
                            >
                              +
                            </button>

                          </div>


                          <Typography
                            color="#33caff"
                            sx={{
                              fontSize: "12px",
                              cursor: "pointer",
                              "&:hover": { textDecoration: "underline" },
                            }}
                            onClick={() =>
                              handleDelete(
                                props.viewshoppingcart.view_all_cart_product._id,
                                carddata.productDetails._id
                              )
                            }
                          >
                            DELETE
                          </Typography>

                          <div
                            style={{
                              height: '1rem',
                              width: '1px',
                              backgroundColor: '#ddd',

                            }}
                          />
                          <Typography
                            color="#33caff"
                            sx={{
                              fontSize: "12px",
                              cursor: "pointer",
                              "&:hover": { textDecoration: "underline" },
                            }}
                          >
                            Save for Later
                          </Typography>
                          <div
                            style={{
                              height: '1rem', // Adjust the height as needed
                              width: '1px',
                              backgroundColor: '#ddd',

                            }}
                          />
                          <Typography
                            color="#33caff"
                            sx={{
                              fontSize: "12px",
                              cursor: "pointer",
                              "&:hover": { textDecoration: "underline" },
                            }}
                          >
                            See more Like this
                          </Typography>
                          <div
                            style={{
                              height: '1rem',
                              width: '1px',
                              backgroundColor: '#ddd',

                            }}
                          />
                          <Typography
                            color="#33caff"
                            sx={{
                              fontSize: "12px",
                              cursor: "pointer",
                              "&:hover": { textDecoration: "underline" },
                            }}
                          >
                            Share
                          </Typography>

                        </Grid>
                      </div>

                    </div>
                  </div>
                  {/* <div style={{ textAlign: "right", marginTop: "1rem" }}>
                    <Typography>Subtotal (1 item): ₹ 154.00</Typography>
                  </div> */}
                </div>

              ))
            ) : (
              <Typography>No items found.</Typography>
            )}
          </div>
        </div>
        <div className="right-side" style={{ flex: 1 }}>
          <div
            className="static-card"
            style={{
              padding: "1rem",
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                justifyItems: "center",
              }}
            >
              <img
                src={righticon}
                alt=""
                className="static-card-image"
                style={{ width: "30px", height: "30px" }}
              />
              <Typography sx={{ fontSize: "13px", color: "#02A6E2" }}>
                Your first order qualifies for FREE Delivery.{" "}
                <span style={{ color: "#000" }}>Select this option at checkout.</span>{" "}
                Details.
              </Typography>
            </div>
            <Typography
              sx={{
                fontSize: "18px",
                marginBottom: "1rem",
                textAlign: "left",
                fontSize: "31px",
              }}
            >
              Subtotal ({totalItems} {totalItems > 1 ? "items" : "item"}): ₹{" "}
              {subtotal.toFixed(2)}
            </Typography>
            <div
              className="checkbox-container"
              style={{
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "left",
                padding: "0 1rem",
              }}
            >
              <input type="checkbox" id="checkbox" />
              <label htmlFor="checkbox" style={{ marginLeft: "0.5rem" }}>
                This order contains a gift
              </label>
            </div>
            <div>
              <Link
                to="/address"
                style={{ display: "block", textAlign: "center", marginTop: "2rem" }}
              >
                <button
                  className="proceed-to-buy-button"

                >
                  Proceed to Buy
                </button>
              </Link>
            </div>
          </div>
        </div>

      </div>

      {/* end */}
      <div id="SHOPPINGPAGE_LAST_CARD_MAIN_BODY_DOWN">
        <div id="SHOPPINGPAGE_LAST_CARD_UP">
          <Typography
            style={{
              fontSize: "30px",
              textAlign: "left",
              fontWeight: "bold",
              marginTop: "5%",
            }}
          >
            {" "}
            Your Items
          </Typography>
          <Box
            sx={{
              width: "100%",
              "@media (max-width:1250px)": {
                width: "50%",
                alignItems: "center",
                marginTop: "2rem",
              },
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                "@media (max-width:1250px)": {
                  width: "60vw",
                },
              }}
            >
              <Tabs
                value={valueone}
                onChange={handleChange}
                aria-label="basic tabs example"
                style={{ marginTop: "2%" }}
              >
                <Tab label="Saved for Later" {...a11yProps(0)} />
                <Tab
                  label="Buy it again"
                  {...a11yProps(1)}
                  sx={{ color: "#FF70A6" }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={valueone} index={0}>
              No items saved for later
            </CustomTabPanel>
            <CustomTabPanel value={valueone} index={1}></CustomTabPanel>
          </Box>
        </div>
        <div id="SHOPPINGPAGE_LAST_CARD_FLEX_ONE">
          <p id="SHOPPINGPAGE_LAST_CARD_FLEX_ONE_PARA_ONE">Need help?</p>
          <p id="SHOPPINGPAGE_LAST_CARD_FLEX_ONE_PARA_TWO">
            Visit the help section{" "}
            <span style={{ fontSize: '25px', fontWeight: '400', color: '#000' }}>or</span>{" "}
            contact us
          </p>
        </div>
      </div>
    </div>
  );
};
