/** @format */

import React from "react";
import "../Bedding Page/BeddingPage.css";
import BeddingImage1 from "../../Assets/BeedingImage1.png";
import BeddingImage2 from "../../Assets/Beedingimage2.png";
import BeddingImage3 from "../../Assets/beddingimage3.png";
import BeddingImage4 from "../../Assets/BeddingImage4.png";
import BeddingImage5 from "../../Assets/BeddingImage5.png";
import BeddingImage6 from "../../Assets/BeddingImage6.png";

export const BeddingPage = () => {
  return (
    <>
      <div id="BeddingPage_MAIN_BODY">
        <div id="BeddingPage_TOP_BODY">
          <h2 id="BeddingPage_HEADING_ONE">Bedding & Nursery</h2>
        </div>
        <div id="Beddingpage_CARD_ALL">
          <div id="Beddingpage_CARD">
            <img src={BeddingImage1} alt="" id="Beddingpage_CARD_IMAGES" />
            <h3 id="Beddingpage_CARD_HEADING_ONE">Bedding & mattresses</h3>
          </div>
          <div id="Beddingpage_CARD">
            <img src={BeddingImage2} alt="" id="Beddingpage_CARD_IMAGES" />
            <h3 id="Beddingpage_CARD_HEADING_ONE">Cots & cradles</h3>
          </div>
          <div id="Beddingpage_CARD">
            <img src={BeddingImage3} alt="" id="Beddingpage_CARD_IMAGES" />
            <h3 id="Beddingpage_CARD_HEADING_ONE">Booster seats &
            high chairs</h3>
          </div>
          <div id="Beddingpage_CARD">
            <img src={BeddingImage4} alt="" id="Beddingpage_CARD_IMAGES" />
            <h3 id="Beddingpage_CARD_HEADING_ONE">Blankets & swaddlers</h3>
          </div>
          <div id="Beddingpage_CARD">
            <img src={BeddingImage5} alt="" id="Beddingpage_CARD_IMAGES" />
            <h3 id="Beddingpage_CARD_HEADING_ONE">Potty seats</h3>
          </div>
          <div id="Beddingpage_CARD">
            <img src={BeddingImage6} alt="" id="Beddingpage_CARD_IMAGES" />
            <h3 id="Beddingpage_CARD_HEADING_ONE">Baby safety</h3>
          </div>
        </div>
      </div>
    </>
  );
};
