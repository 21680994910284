import React from "react";
import "../styles/Layout.css";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import formLogo from "../../../Assets/kidztrynewlog.png";
import Snackbar from "../../../common/snackbar/components/snackbar";
// import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
// import  LoaderCon  from "../../../common/loader/containers/loader_cont";
import { Link, useNavigate } from "react-router-dom";
import AllSlidePage from "../../Images pages/container/ImagesPageCont";
import { RectanglePage } from "../../Rectanglepage/RectanglePage";
import FocusOnPage from "../../FocusonPage/FocusOnpage";
import { BeddingPage } from "../../Bedding Page/BeddingPage";
import { BathPage } from "../../BathPage/BathPage";
import { BabyfeddingPage } from "../../BabyFedding Page/BabyFeddingPage";
import { ViewmorePage } from "../../Viewmore Page/ViewmorePage";
import ShopbyAgePage from "../../ShopByAge Page/Container/shopByageCont";
import { CuratestorePage } from "../../Curatesstore Page/Curatespage";
// import { ExploreBrandPage } from "../../Explorebrand page/ExploreBrandPage";
import DealsOnCare from "../../Deals on Baby Care/Containers/DealsOnBayCont";
import ExploreBrandPage from "../../Explorebrand page/Components/ExploreBrandPage";
import ExploreBrandCont from "../../Explorebrand page/Container/ExploreBrandCont";
import { Typography } from "@mui/material";
import kidtryzmainlogo from "../../../Assets/kidtryz.png";
const LayoutPage = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    props.viewCategories();
    props.viewBanner();
    return () => {};
  }, []);
  // console.log(props.categories.all_categories);
  if (
    !props.categories.all_categories ||
    props.categories.all_categories.length === 0
  ) {
    // Handle the case where no categories are available
    return (
      <p style={{ color: "red", fontSize: "68px", textAlign: "center" }}>
        No data found
      </p>
    );
  }

  return (
    <>
      {showLoader && (
        <div className="logoLoder">
          <img src={formLogo} alt="Loading..." />
        </div>
      )}
      {/* <div id="Slidemarquee-container">
        <Marquee
          speed={20}
          className="MainMarquee"
          style={{
            backgroundColor: "#FF70A6",
            height: 195,
            overflow: "hidden",
          }}
        >
          {Array.isArray(props.categories.all_categories) &&
            props.categories.all_categories.map((items) => (
              <>
                <div>
                  <div id="Layoutpage_main_map_body">
                    {" "}
                    <Link
                      to={`/proucts/${items.cat_name}`}
                      onClick={() => {
                        props.setCatId(items.cat_name);
                        console.log(items.cat_name);
                      }}
                    >
                      <img src={items.logo} alt="" id="SlideImage1" />
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={`/proucts/${items._id}`}
                      onClick={() => {
                        props.setCatId(items.cat_name);
                        console.log(items.cat_name);
                      }}
                      id="SlideImageHeading1"
                    >
                      {items.cat_name}
                    </Link>
                  </div>
                </div>
              </>
            ))}
        </Marquee>
      </div> */}
      <div
        id="Slidemarquee-container"
        style={{
          position: "relative",
          overflow: "hidden",
          overflowX: "hidden",
          backgroundColor: "#FFDCE9",
          display: "flex",
          alignItems: "center",
          border: "none",
          height: "115px",
        }}
      >
        {Array.isArray(props.categories.all_categories) &&
          props.categories.all_categories.length > 0 && (
            <div id="static-category">
              <div id="Layoutpage_main_map_body">
                <Link
                  to={`/products/${props.categories.all_categories[0].cat_name}`}
                  onClick={() => {
                    props.setCatId(props.categories.all_categories[0].cat_name);
                  }}
                >
                  <div
                    style={{
                      width: "90px",
                      height: "90px",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      marginTop: "5px",
                    }}
                    className="collboration-logo-main"
                  >
                    <img
                      src={kidtryzmainlogo}
                      alt=""
                      className="firstslide-mainlogo"
                      style={{
                        width: "70%",
                        height: "auto",
                        objectFit: "contain",
                        margin: "0px 5px",
                      }}
                    />
                  </div>
                </Link>
              </div>
              <div>
                <Link
                  to={`/products/${props.categories.all_categories[0]._id}`}
                  onClick={() => {
                    props.setCatId(props.categories.all_categories[0].cat_name);
                  }}
                  id="SlideImageHeading1"
                >
                  <p id="colla">Collaboration</p>
                </Link>
              </div>
            </div>
          )}

        <Marquee
          speed={0}
          className="MainMarquee"
          style={{
            whiteSpace: "nowrap",
            overflowX: "auto",
            height: "max-content",
            scrollbarWidth: "none",
            marginTop: "28px",
          }}
        >
          {Array.isArray(props.categories.all_categories) &&
          props.categories.all_categories.length > 1 ? (
            props.categories.all_categories.slice(1).map((items, index) => (
              <div
                key={items._id}
                style={{
                  display: "flex",
                  backgroundColor: index === 0 ? "#FFDCE9" : "inherit",
                }}
              >
                <div id="Layoutpage_main_map_body">
                  <Link
                    to={`/products/${items.cat_name}`}
                    onClick={() => {
                      props.setCatId(items.cat_name);
                    }}
                  >
                    <img src={items.logo} alt="" id="SlideImage1" />
                    <p
                      style={{
                        marginTop: "-5px",
                        color: "#0f0d0e",
                        fontSize: "17px",
                        fontWeight: "800",
                        textAlign: "center",
                      }}
                      className="collboration-cat-name"
                    >
                      {items.cat_name}
                    </p>
                  </Link>
                </div>
                {items._id ? (
                  <div>
                    <Link
                      to={`/products/${items._id}`}
                      onClick={() => {
                        props.setCatId(items.cat_name);
                      }}
                      id="SlideImageHeading1"
                    ></Link>
                  </div>
                ) : (
                  <div>
                    <p
                      style={{
                        color: "red",
                        fontSize: "58px",
                        textAlign: "center",
                      }}
                    >
                      No data found
                    </p>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p style={{ color: "red", fontSize: "68px", textAlign: "center" }}>
              No data found
            </p>
          )}
        </Marquee>
      </div>

      <AllSlidePage />
      <RectanglePage />
      <DealsOnCare />
      {/* <NcorouselPage /> */}
      {/* <NDealsOnPage/> */}
      <FocusOnPage />
      <BeddingPage />
      <BathPage />
      {Array.isArray(props.banner.all_banner) &&
        props.banner.all_banner.map((item, index) => (
          <div className="babyfeedimg">
            {item.categoryName === "home center" && (
              <img src={item.imageOrVideoUpload} alt="" id="GROUP_ONE_IMAGE" />
            )}
          </div>
        ))}
      <BabyfeddingPage />
      {Array.isArray(props.banner.all_banner) &&
        props.banner.all_banner.map((item, index) => (
          <div className="babyfeedimg">
            {item.categoryName === "flat 60% off" && (
              <img
                src={item.imageOrVideoUpload}
                alt=""
                id="GROUP_ONE_IMAGE"
                className="add-banner-carousel"
              />
            )}
          </div>
        ))}
      {/* <ViewmorePage /> */}
      {/* <CuratestorePage /> */}
      <ShopbyAgePage />
      {/* <ExploreBrandPage /> */}
      <ExploreBrandCont />
      {Array.isArray(props.banner.all_banner) &&
        props.banner.all_banner.map((item, index) => (
          <div id="EXPLOREAGEPAGE_BACKGROUNDIMAGE_ONE">
            {item.categoryName === "footer - 1" && (
              <div className="foot_1 footDesign">
                <img
                  src={item.imageOrVideoUpload}
                  alt=""
                  id="EXPLOREPAGE_BACKGROUNDIMAGE_UNDER_ONE"
                />
              </div>
            )}
            {item.categoryName === "footer - 2" && (
              <div className="foot_3">
                <img
                  src={item.imageOrVideoUpload}
                  alt=""
                  id="EXPLOREPAGE_BACKGROUNDIMAGE_UNDER_ONE"
                />
              </div>
            )}
            <div>
              {item.categoryName === "footer - 3" && (
                <img
                  src={item.imageOrVideoUpload}
                  alt=""
                  id="EXPLOREPAGE_BACKGROUNDIMAGE_UNDER_ONE"
                />
              )}
            </div>
          </div>
        ))}
    </>
  );
};
export default LayoutPage;
