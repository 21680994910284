import delivery_combo_product_1 from '../../Assets/delivery_combo_product_1.png';
import delivery_combo_product_2 from '../../Assets/delivery_combo_product_2.png';
import delivery_combo_product_3 from '../../Assets/delivery_combo_product_3.png';



export const preDeliveryProducts = [
  {
    Img:delivery_combo_product_1,
    details:'LuvLap Silicone FoodFruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink',
    afterDiscount:199,
    beforeDiscount:154,
    percentageDiscount:23,
    reviewData:4.2,
    reviewPerson:'9,682'
  },
  {
    Img:delivery_combo_product_1,
    details:'LuvLap Silicone FoodFruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink',
    afterDiscount:199,
    beforeDiscount:154,
    percentageDiscount:23,
    reviewData:4.2,
    reviewPerson:'9,682'
  },
  {
    Img:delivery_combo_product_1,
    details:'LuvLap Silicone FoodFruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink',
    afterDiscount:199,
    beforeDiscount:154,
    percentageDiscount:23,
    reviewData:4.2,
    reviewPerson:'9,682'
  },
  {
    Img:delivery_combo_product_2,
    details:'LuvLap Silicone FoodFruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink',
    afterDiscount:199,
    beforeDiscount:154,
    percentageDiscount:23,
    reviewData:4.2,
    reviewPerson:'9,682'
  },
  {
    Img:delivery_combo_product_2,
    details:'LuvLap Silicone FoodFruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink',
    afterDiscount:199,
    beforeDiscount:154,
    percentageDiscount:23,
    reviewData:4.2,
    reviewPerson:'9,682'
  },
  {
    Img:delivery_combo_product_2,
    details:'LuvLap Silicone FoodFruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink',
    afterDiscount:199,
    beforeDiscount:154,
    percentageDiscount:23,
    reviewData:4.2,
    reviewPerson:'9,682'
  },
  {
    Img:delivery_combo_product_3,
    details:'LuvLap Silicone FoodFruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink',
    afterDiscount:199,
    beforeDiscount:154,
    percentageDiscount:23,
    reviewData:4.2,
    reviewPerson:'9,682'
  },
  {
    Img:delivery_combo_product_3,
    details:'LuvLap Silicone FoodFruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink',
    afterDiscount:199,
    beforeDiscount:154,
    percentageDiscount:23,
    reviewData:4.2,
    reviewPerson:'9,682'
  },
  {
    Img:delivery_combo_product_3,
    details:'LuvLap Silicone FoodFruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink',
    afterDiscount:199,
    beforeDiscount:154,
    percentageDiscount:23,
    reviewData:4.2,
    reviewPerson:'9,682'
  },
]