/** @format */

import React from "react";
import "../Rectanglepage/RectanglePage.css";
import RectangleImage1 from "../../Assets/rectangle image1.png";
import Rectangleimage2 from "../../Assets/rectangle image2.png";
import Rectangleimage3 from "../../Assets/rectange image3.png";
import Rectangleimage4 from "../../Assets/rectangle image4.png";
import Rectangleimage5 from "../../Assets/rectangle image 5.png";
import Rectangleimage6 from "../../Assets/rectangle image6.png";

export const RectanglePage = () => {
  return (
    <>
      <div id="Diapering_MAIN_BODY">
        <div id="Diapering_TOP-BODY">
          <h2 id="Diapering_HEADING_ONE">Diapering</h2>
        </div>
        <div id="Diapering_CARD_ALL">
          <div id="Diapering_CARD">
            <img src={RectangleImage1} alt="" id="Diapering_CARD_IMAGES" />
            <h3 id="Diapering_CARD_HEADING">Diaper pants</h3>
          </div>
          <div id="Diapering_CARD">
            <img src={Rectangleimage2} alt="" id="Diapering_CARD_IMAGES" />
            <h3 id="Diapering_CARD_HEADING">Wet wipes</h3>
          </div>
          <div id="Diapering_CARD">
            <img src={Rectangleimage3} alt="" id="Diapering_CARD_IMAGES" />
            <h3 id="Diapering_CARD_HEADING">Cloth diapers & langots</h3>
          </div>
          <div id="Diapering_CARD">
            <img src={Rectangleimage4} alt="" id="Diapering_CARD_IMAGES" />
            <h3 id="Diapering_CARD_HEADING">Taped diapers</h3>
          </div>
          <div id="Diapering_CARD">
            <img src={Rectangleimage5} alt="" id="Diapering_CARD_IMAGES" />
            <h3 id="Diapering_CARD_HEADING">Premium diapers</h3>
          </div>
          <div id="Diapering_CARD">
            <img src={Rectangleimage6} alt="" id="Diapering_CARD_IMAGES" />
            <h3 id="Diapering_CARD_HEADING">Changing sets</h3>
          </div>
        </div>
      </div>
    </>
  );
};
