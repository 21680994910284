import React, { useState } from "react";
import GooglePayImage from "../../Assets/Googleimg.png";
import PhonePeImage from "../../Assets/Phonepeimg.png";
import UpiImage from "../../Assets/upiimg.png";
import './Paymen.css'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Divider from '@mui/material/Divider';
import StepLabel from '@mui/material/StepLabel';
import { styled, Button, Grid } from "@mui/material";
function Payment() {
  const steps = [
    'Address',
    'Order Summary',
    'Payment',
  ];
  const [selectedPayment, setSelectedPayment] = useState("");

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };
  const PinkButton = styled(Button)({
    backgroundColor: "#FF70A6",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FF70A6",
    },
  });

  return (
    <div
      style={{
        backgroundColor: "#F8F8F8",
        height: "max-content",
        paddingBottom: '10px'
      }}
    >
      <Grid sx={{ width: '100%', paddingTop: '30px' }}>
        <Stepper activeStep={2} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                sx={{
                  '& .MuiStepLabel-label': {
                    color: '#000', // Change the label text color to green
                  },
                  '& .MuiStepIcon-root': {
                    color: '#bdbdbd', // Change the icon color to green for completed and active steps
                    '&.Mui-active': {
                      color: 'green', // Change the active step icon color to green
                    },
                    '&.Mui-completed': {
                      color: 'green', // Change the completed step icon color to green
                    },
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

      </Grid>

      <div
        className="payment_pageoptions"
        style={{
          backgroundColor: "white",
          width: "80%",
          maxWidth: "800px", // Limits max width on larger screens
          height: "max-content",
          margin: "2rem auto", // Center align on smaller screens
          overflow: "hidden",
          position: "relative",
          padding: "20px", // Add padding to reduce margin need
        }}
      >
        <h4
          style={{
            color: "#FF70A6",
            fontSize: "clamp(18px, 5vw, 25px)", // Responsive font size
            fontWeight: "700",
            marginLeft: "5%",
            paddingBottom: '20px',
          }}
        >
          Payment Option
        </h4>
        <Divider variant="middle"  sx={{width:'100%',margin:'0px 0px'}}/>

        <label style={{ gap: '12px', display: 'flex', marginTop: '2%', marginLeft: "5%" }}>
          <input
            type="radio"
            name="paymentMethods"
            value="Google Pay"
            checked={selectedPayment === "Google Pay"}
            onChange={handlePaymentChange}
          />
          <img
            style={{
              marginLeft: "1%",
              marginRight: "1%",
              width: "30px",
              height: "30px",
            }}
            src={GooglePayImage}
            alt="Google Pay"
          />
          <strong
            style={{
              fontWeight: selectedPayment === "Google Pay" ? "bold" : "normal",
              fontSize: "clamp(14px, 3vw, 20px)", // Responsive font size
            }}
          >
            Google Pay
          </strong>
        </label>
        <label style={{ gap: '12px', display: 'flex', marginTop: "5%", marginLeft: "5%" }}>
          <input
            type="radio"
            name="paymentMethods"
            value="PhonePe"
            checked={selectedPayment === "PhonePe"}
            onChange={handlePaymentChange}
          />
          <img
            style={{
              marginLeft: "1%",
              marginRight: "1%",
              width: "30px",
              height: "30px",
            }}
            src={PhonePeImage}
            alt="PhonePe"
          />
          <strong
            style={{
              fontWeight: selectedPayment === "PhonePe" ? "bold" : "normal",
              fontSize: "clamp(14px, 3vw, 20px)", // Responsive font size
            }}
          >
            PhonePe
          </strong>
        </label>
        <label style={{ gap: '12px', display: 'flex', marginTop: "5%", marginLeft: "5%" }}>
          <input
            type="radio"
            name="paymentMethods"
            value="UPI"
            checked={selectedPayment === "UPI"}
            onChange={handlePaymentChange}
          />
          <img
            style={{
              marginLeft: "1%",
              marginRight: "1%",
              width: "25px",
              height: "25px",
            }}
            src={UpiImage}
            alt="UPI"
          />
          <strong
            style={{
              fontWeight: selectedPayment === "UPI" ? "bold" : "normal",
              fontSize: "clamp(14px, 3vw, 20px)", // Responsive font size
            }}
          >
            UPI
          </strong>
        </label>
        <label style={{ gap: '12px', display: 'flex', marginTop: "5%", marginLeft: "5%" }}>
          <input
            type="radio"
            name="paymentMethods"
            value="Wallets"
            checked={selectedPayment === "Wallets"}
            onChange={handlePaymentChange}
          />
          <strong
            style={{
              fontWeight: selectedPayment === "Wallets" ? "bold" : "normal",
              fontSize: "clamp(14px, 3vw, 20px)", // Responsive font size
              marginLeft: "1%",
            }}
          >
            Wallets
          </strong>
        </label>
        <label style={{ gap: '12px', display: 'flex', marginTop: "5%", marginLeft: "5%" }}>
          <input
            type="radio"
            name="paymentMethods"
            value="Credit/Debit/ATM Card"
            checked={selectedPayment === "Credit/Debit/ATM Card"}
            onChange={handlePaymentChange}
          />
          <strong
            style={{
              fontWeight:
                selectedPayment === "Credit/Debit/ATM Card" ? "bold" : "normal",
              fontSize: "clamp(14px, 3vw, 20px)", // Responsive font size
              marginLeft: "1%",
            }}
          >
            Credit/Debit/ATM Card
          </strong>
        </label>
        <label style={{ gap: '12px', display: 'flex', marginTop: "5%", marginLeft: "5%" }}>
          <input
            type="radio"
            name="paymentMethods"
            value="Net Banking"
            checked={selectedPayment === "Net Banking"}
            onChange={handlePaymentChange}
          />
          <strong
            style={{
              fontWeight: selectedPayment === "Net Banking" ? "bold" : "normal",
              fontSize: "clamp(14px, 3vw, 20px)", // Responsive font size
              marginLeft: "1%",
            }}
          >
            Net Banking
          </strong>
        </label>
        <label style={{ gap: '12px', display: 'flex', marginTop: "5%", marginLeft: "5%" }}>
          <input
            type="radio"
            name="paymentMethods"
            value="Cash on Delivery"
            checked={selectedPayment === "Cash on Delivery"}
            onChange={handlePaymentChange}
          />
          <strong
            style={{
              fontWeight:
                selectedPayment === "Cash on Delivery" ? "bold" : "normal",
              fontSize: "clamp(14px, 3vw, 20px)", // Responsive font size
              marginLeft: "1%",
            }}
          >
            Cash on Delivery
          </strong>
        </label>
        {/* Add more radio buttons and corresponding text for additional payment options as needed */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end', // Aligns the button to the right
            width: '100%', // Ensures the container takes up the full width
          }}
        >
          <PinkButton
            style={{ width: "100px", marginTop: "5%" }}
            variant="contained"
          >
            Pay
          </PinkButton>
        </div>

      </div>

    </div>
  );
}

export default Payment;
