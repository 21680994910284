/** @format */

import React, { useState, useEffect } from "react";
import { FaHeart } from "react-icons/fa";
// import "../styles/MyWishlistPage.css";
import "../styles/NewWishlist.css";
import { Link } from "react-router-dom";
// import { Card, CardContent, CardMedia, Typography ,Button} from "@mui/material";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Button,
  Grid,
} from "@mui/material";

import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

const MyWishlistPage = (props) => {
  const [isAdded, setAdded] = useState(false);
  const [add, setadd] = useState(false);
  const [plus, setplus] = useState(false);
  const [Idtodeletewishlist, setIdtodeletewishlist] = useState();

  useEffect(() => {
    props.viewAllWishlist(props.login.admin_id);
    return () => {};
  }, []);

  function calculateRegularPrice(mrp, discountPercentage) {
    const discountDecimal = discountPercentage / 100;
    const discountAmount = mrp * discountDecimal;
    const regularPrice = mrp - discountAmount;
    return regularPrice.toFixed(2);
  }

  // Function to remove duplicate items based on id
  const uniqueWishlist = (wishlist) => {
    const seen = new Set();
    return wishlist.filter((item) => {
      const duplicate = seen.has(item.productDetails._id);
      seen.add(item.productDetails._id);
      return !duplicate;
    });
  };

  return (
    <>
      <div className="kwishlist-main">
        {/* <div className="group">
        <svg className="icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
        <input placeholder="Search" type="search" className="input" />
      </div> */}
        <div className="kwishlist-heading">
          <h3>My Wishlist</h3>
        </div>
        <Grid container spacing={2} className="kwishlist-card-grid" >
  {Array.isArray(props.wishlist.all_view_wishlist) &&
    uniqueWishlist(props.wishlist.all_view_wishlist).map((wishitem) => (
      <Grid item xs={12} sm={6} md={4} lg={2.6} key={wishitem.productDetails._id}>
        <Card sx={{ boxShadow: 3, position: 'relative', padding: '10px' }}>
          <CardMedia
            component="img"
            image={wishitem.productDetails.images}
            alt={wishitem.productDetails.name}
            sx={{
              height: {  md: '200px' }, // Responsive image height
              objectFit: 'contain', // Fit the image within the card while maintaining its aspect ratio
              margin: 'auto',  // Center the image within the card
              width: '100%',
            }}
          />

                  <IconButton
                    onClick={() =>
                      props.deletewishlist(wishitem._id, props.login.admin_id)
                    }
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: 8,
                      color: "red",
                      cursor: "pointer",
                    }}
                  >
                    <FaHeart fontSize={33} />
                  </IconButton>

                  <CardContent>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1.1rem" },
                        fontWeight: "bold",
                      }}
                    >
                      {wishitem.productDetails.name.length > 25
                        ? wishitem.productDetails.name.slice(0, 25) + "..."
                        : wishitem.productDetails.name}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Typography variant="body1" component="span">
                        MRP: ₹ <del>{wishitem.productDetails.regularPrice}</del>
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          margin: "0 1px",
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        {wishitem.productDetails.regularPrice -
                          (wishitem.productDetails.regularPrice *
                            wishitem.productDetails.discountPercentage) /
                            100}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ margin: "0 1px" }}
                      >
                        |
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ color: "#ff70a6" }}
                      >
                        {wishitem.productDetails.discountPercentage} % Off
                      </Typography>
                    </div>
                  </CardContent>

                  <CardActions>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#33caff",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor: "#33caff",
                        },
                      }}
                    >
                      Move To Bag
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
};

export default MyWishlistPage;
