/** @format */

import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import "./Coupon.css";
function Coupons() {
  const items = [
    {
      offer: "Extra 40% Off",
      valid: "Valid till 30 Apr, 2024",
      details:
        "Get extra 50% off up to ₹50 on 1 item(s) (price inclusive of cashback/coupon)",
      terms: "View T&C",
    },
    {
      offer: "Extra 40% Off",
      valid: "Valid till 30 Apr, 2024",
      details:
        "Get extra 50% off up to ₹50 on 1 item(s) (price inclusive of cashback/coupon)",
      terms: "View T&C",
    },
    {
      offer: "Extra 40% Off",
      valid: "Valid till 30 Apr, 2024",
      details:
        "Get extra 50% off up to ₹50 on 1 item(s) (price inclusive of cashback/coupon)",
      terms: "View T&C",
    },
    // Add more items as needed...
  ];

  return (
    
    <div className="main_coupon">
      <div className="offers_coupon">
        <Typography
          variant="h4"
          sx={{
            fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" }, // Responsive font size
            fontWeight: "bold",
            color: "#000",
            padding:'30px 0px'
          }}

        >
          Available Coupons
        </Typography>
        {items.map((item, index) => (
          <div key={index} className="coupon_card">
            <Grid className="coupon_header">
              <Typography className="coupon_offer"  sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" }, // Responsive font size
                  fontWeight: "bold",
                  color: "#FF5722",
                }}>{item.offer}</Typography>
              <Typography className="coupon_valid"  sx={{
                  fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
                  color: "#757575",
                }}>{item.valid}</Typography>
            </Grid>
            <Grid className="coupon_body">
              <Typography className="coupon_details" sx={{
                    fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
                    color: "#424242",
                  }}>{item.details}</Typography>
              <Typography className="coupon_terms" sx={{
                    fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" }, // Responsive font size
                    color: "#9E9E9E",
                  }}>{item.terms}</Typography>
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Coupons;
