import React, { Component } from "react";
import ProductbyAge from "../components/ProductbyAge";
import { connect } from "react-redux";
import { ViewProductbyAge } from "../action";
import { close_snack_bar } from "../../../common/snackbar/action";


export class Controller extends Component {
  render() {
    return <ProductbyAge {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    categories: store.categories,
    allfilterproduct: store.allfilterproduct,
    age: store.age,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    ViewProductbyAge: (age) => {
      dispatch(ViewProductbyAge(age));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
