import React from "react";
import { Navigate, Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import "../../common/navbar/index.css";
import "../styles/drawer.css";

export default class LG extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  handleLogout = () => {
    localStorage.removeItem("mio_mobile");
    // Uncomment these if needed
    // localStorage.removeItem("sre_name");
    // localStorage.removeItem("qubi7_profile_pic");
    // localStorage.removeItem("sre_user_id");
    // localStorage.removeItem("qubi7_company_id");
    // localStorage.removeItem("taxopliance_organization_id");
    
    this.setState({ redirect: true });
    this.props.onLogout();
  };

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Navigate to="/" />;
    }

    return (
      <Link to="/" style={{ textDecoration: "none" }}>
        <Button
          className="logout-button1"
          style={{ textDecoration: "none", marginBottom: "10px" }}
          onClick={this.handleLogout}
        >
          <Typography style={{ color: "white" }}>
            <LogoutIcon style={{ marginTop: "-5px" }} />
            Log Out
          </Typography>
        </Button>
      </Link>
    );
  }
}
