import "firebase/storage";
import { setLoader, unsetLoader } from "../../common/loader/action";
import UNIVERSAL from "../../config/config";
import { set_snack_bar } from "../../common/snackbar/action";
import { VIEW_CART_PRODUCT } from "./constant";



export function viewCartProduct(token) {
  return async (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/cart-products", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch cart products");
        }
        return response.json();
      })
      .then((responseJson) => {
        console.log("View cart products response:", responseJson); 
        if (responseJson?.result?.data?.result?.products) {
          const cartItems = responseJson.result.data.result.products || [];
          dispatch({
            type: VIEW_CART_PRODUCT,
            payload: {
              data: cartItems,
            },
          });
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch({ type: VIEW_CART_PRODUCT, payload: { data: [], count: 0 } });
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error("Error fetching cart products:", error);  // Debugging log
        dispatch(set_snack_bar(true, "An error occurred while fetching cart products"));
        dispatch(unsetLoader());
      });
  };
}


export function delete_carditem(cartid, productId, token) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/remove-cart-product/" + cartid, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify({
        productIds: productId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete cart item");
        }
        return response.json();
      })
      .then((responseJson) => {
        console.log("Delete response:", responseJson);  // Debugging log
        if (responseJson.status) {
          dispatch(viewCartProduct(token));  // Only update the cart if deletion is successful
        } else {
          console.error("Deletion failed:", responseJson.message);
        }
        dispatch(set_snack_bar(true, responseJson.message));
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error("Error deleting cart item:", error);  // Debugging log
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        dispatch(unsetLoader());
      });
  };
}
