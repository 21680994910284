import React, { Component } from "react";
import { FilterCard } from "../components/FilterPageCard";
import { connect } from "react-redux";
import { viewCatergoriesById } from "../action";
import { viewDescription } from "../../Product View/Action";
import { close_snack_bar } from "../../../common/snackbar/action";

export class Controller extends Component {
  render() {
    return <FilterCard {...this.props}  />;
  }
}
export const mapStateToProps = (store) => {
  return {
    categories: store.categories,
    allfilterproduct: store.allfilterproduct,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewCatergoriesById: (id) => {
      dispatch(viewCatergoriesById(id));
    },
    viewDescription: (id) => {
      dispatch(viewDescription(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
