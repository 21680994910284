import React, { Component } from "react";
import { connect } from "react-redux";
import { viewCategories, viewDescription, addToCart, addWishlist } from "../Action";
import ProductAllView from "../Components/ProductView";

export class Controller extends Component {
  render() {
    return <ProductAllView {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    categories: store.categories,
    descrption: store.descrption,
    cart: store.cart,
    wishlist: store.wishlist,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewCategories: () => {
      dispatch(viewCategories());
    },
    viewDescription: (id) => {
      dispatch(viewDescription(id));
    },
    addToCart: (token, productIds) => {
      dispatch(addToCart(token, productIds));
      console.log(token, productIds);
    },
    addWishlist: (
      token,
      productId,
    ) => {
      dispatch(
        addWishlist(
          token,
          productId,
        )
      );
      // console.log(token, productId);
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
