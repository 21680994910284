import React, { useEffect } from "react";
import "./Bestseller.css";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import businessimage from '../../Assets/undraw_business_deal_re_up4u 2 1.png'
import formLogo from "../../Assets/kidztrynewlog.png";
import bindu from "../../Assets/bindu.png";

const BestSeller = () => {
  const cards = [
    {
      title: "Our Origin",
      description: "Discover how we started our journey and the inspiration behind our mission.",
      icon: "🌟" // Placeholder for icon
    },
    {
      title: "Milestone 1",
      description: "Learn about the first major milestone we achieved and the impact it had.",
      icon: "🏆" // Placeholder for icon
    },
    {
      title: "Growth Phase",
      description: "Explore the phases of growth we went through and the key developments during that time.",
      icon: "📈" // Placeholder for icon
    },
    {
      title: "Future Vision",
      description: "See what the future holds for us and our plans to continue evolving and innovating.",
      icon: "🔮" // Placeholder for icon
    }
  ];


  return (
    <>
      <div className="seller_container">
        <div className="seller_main">
          <div className="inner_image_container_seller">
            <img src={businessimage} alt="Description" />
          </div>

          <div className="overlay_content">
            <h2>Sell Online with Kidtryz</h2>
            <p>Welcome to Kidtryz, your go-to platform for buying and selling children's items. Easily find great deals on gently used toys and clothes, or declutter by selling your outgrown items. Enjoy a seamless experience whether you're buying or selling!</p>
            <button>Start Selling</button>
          </div>
        </div>
      </div>
      <div className="features_section">
        <div id="why_sell"><p>Why Sell with <img src={formLogo}></img> ?</p> </div>
        <div className="why_sell_containers">
          <div className="why_sell_box_container">
            <div className="why_sell_box">
              <p id="bindu_head"><img src={bindu} alt="" srcset="" /> User-Friendly Interface</p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro cupiditate suscipit, obcaecati minus neque,
                aspernatur quis repudiandae quod rerum omnis culpa aliquam a similique facilis velit pariatur quidem molestias
                officiis.</p>
            </div>
          </div>
          <div className="why_sell_box_container">
            <div className="why_sell_box">
              <p id="bindu_head"><img src={bindu} alt="" srcset="" /> User-Friendly Interface</p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro cupiditate suscipit, obcaecati minus neque,
                aspernatur quis repudiandae quod rerum omnis culpa aliquam a similique facilis velit pariatur quidem molestias
                officiis.</p></div>
                </div>
            <div className="why_sell_box_container">
            
            <div className="why_sell_box">
              <p id="bindu_head"><img src={bindu} alt="" srcset="" /> User-Friendly Interface</p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro cupiditate suscipit, obcaecati minus neque,
                aspernatur quis repudiandae quod rerum omnis culpa aliquam a similique facilis velit pariatur quidem molestias
                officiis.</p></div>
                </div>
            <div className="why_sell_box_container">
            
            <div className="why_sell_box">
              <p id="bindu_head"><img src={bindu} alt="" srcset="" /> User-Friendly Interface</p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro cupiditate suscipit, obcaecati minus neque,
                aspernatur quis repudiandae quod rerum omnis culpa aliquam a similique facilis velit pariatur quidem molestias
                officiis.</p></div>
                </div>
            <div className="why_sell_box_container">
            
            <div className="why_sell_box">
              <p id="bindu_head"><img src={bindu} alt="" srcset="" /> User-Friendly Interface</p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro cupiditate suscipit, obcaecati minus neque,
                aspernatur quis repudiandae quod rerum omnis culpa aliquam a similique facilis velit pariatur quidem molestias
                officiis.</p>
                </div>
          </div>
        </div>
      </div>
      <div className="how_it_started_section">
        <div className="section_header">
          <button className="section_button">How to get Started?</button>
        </div>
        <div className="cards_wrapper">
       <div>
       <div></div>
       <div></div>
       </div>
        <div>
        <div></div>
        <div></div>
        </div>
        </div>
        <h1> Join Kidtryz today and turn your gently used children's items into cash, while helping other families find what they need. Happy selling!</h1>

      </div>

    </>
  );
};
export default BestSeller;
