import React from "react";
import './404.css';
export default class Error extends React.Component {
    render() {
        return (
            <div className="not-found-body">
                <div className="not-found-container">
                    <h1 className="error-code">404</h1>
                    <p className="error-message">Page Not Found</p>
                    <a href="/" className="home-link">Go Back Kidtryz</a>
                </div>
            </div>
        )
    }
}