import React, { Component } from "react";
import { connect } from "react-redux";
// import { ExploreBrandPage } from "../Components/ExploreBrandPage";
import { ViewBrands } from "../Action";
import { close_snack_bar } from "../../../common/snackbar/action";

import ExploreBrandPage from "../Components/ExploreBrandPage";

export class Controller extends Component {
  render() {
    return <ExploreBrandPage {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    brand: store.brand,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    ViewBrands: () => {
      dispatch(ViewBrands());
    },
  };
}; 
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
