import React, { Component } from "react";
import { connect } from "react-redux";
import { deletewishlist, viewAllWishlist } from "../action";
import MyWishlistPage from "../components/MyWishlistPage";
import { close_snack_bar } from "../../../common/snackbar/action";

export class Controller extends Component {
  render() {
    return <MyWishlistPage {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    categories: store.categories,
    login: store.login,
    wishlist: store.wishlist,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllWishlist: (token) => {
      dispatch(viewAllWishlist(token));

    },
    deletewishlist: (itemId,token) => {
      dispatch(deletewishlist(itemId,token))
 console.log(itemId);
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
