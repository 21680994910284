import React, { Component } from "react";
import LayoutPage from "../Component/ShopbyAge";
import { connect } from "react-redux";
import { viewCategories, setAge } from "../action";
import { close_snack_bar } from "../../../common/snackbar/action";

export class Controller extends Component {
  render() {
    return <LayoutPage {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    categories: store.categories,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewCategories: () => {
      dispatch(viewCategories());
    },
    setAge: (id) => {
      dispatch(setAge(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
