import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom'

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ButtonBase from '@mui/material/ButtonBase';
import { FaCircle, FaRegCircle } from "react-icons/fa";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import rectangleImage from '../../../Assets/Rectangle 928.png';
import rectangle from '../../../Assets/Rectangle 931.png'
import { useEffect } from 'react';
import { IoArrowBackCircleOutline } from "react-icons/io5";

import './Orders.css'
const Orders = (props) => {
  const [orderStatusChecked, setOrderStatusChecked] = React.useState([true, false, false, false]);
  const [orderTimeChecked, setOrderTimeChecked] = React.useState([false, false, false]);

  const handleOrderStatusChange = (index) => {
    const newOrderStatusChecked = [false, false, false, false];
    newOrderStatusChecked[index] = true;
    setOrderStatusChecked(newOrderStatusChecked);
  };

  const handleOrderTimeChange = (index) => {
    const newOrderTimeChecked = [false, false, false];
    newOrderTimeChecked[index] = true;
    setOrderTimeChecked(newOrderTimeChecked);
  };

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  useEffect(() => {
    props.viewCartOrder(props.login.admin_id);
    return () => { };
  }, []);
  console.log(props.order.view_all_order);
  function truncateText(text, wordLimit) {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  }
  return (
    <div className='orders' style={{ display: 'flex', padding: '30px' }}>
      {/* Filters Section */}
      <Grid
      className='filter_order'
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        sx={{
          width: {
            xs: '50%',
            sm: '50%',
            md: '33.33%',
            lg: '25%',
            xl: '15%',
          },
          backgroundColor: "#f9f9f9",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: { xs: '10px', sm: '15px', md: '20px' },
            marginTop: { xs: '5px', sm: '10px' },
            height: 'max-content',
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontSize: { xs: '17px', sm: '25px', md: '30px' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            Filters
          </Typography>

          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: '14px', sm: '18px', md: '20px' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            Order Status
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              ml: { xs: 0, sm: 1 },
              mt: { xs: 0, sm: -3 },
            }}
          >
            <FormControlLabel
              sx={{
                pt: { xs: 1, sm: 3 },
                '& .MuiTypography-root': {
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px', xl: '20px' }
                }
              }}
              label="On the way"
              control={
                <Checkbox
                  checked={orderStatusChecked[0]}
                  onChange={() => handleOrderStatusChange(0)}
                />
              }
            />
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px', xl: '20px' }
                }
              }}
              label="Delivered"
              control={
                <Checkbox
                  checked={orderStatusChecked[1]}
                  onChange={() => handleOrderStatusChange(1)}
                />
              }
            />
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px', xl: '20px' }
                }
              }}
              label="Cancelled"
              control={
                <Checkbox
                  checked={orderStatusChecked[2]}
                  onChange={() => handleOrderStatusChange(2)}
                />
              }
            />
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px', xl: '20px' }
                }
              }}
              label="Returned"
              control={
                <Checkbox
                  checked={orderStatusChecked[3]}
                  onChange={() => handleOrderStatusChange(3)}
                />
              }
            />
          </Box>


          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: '14px', sm: '18px', md: '20px' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            Order Time
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              ml: { xs: 0, sm: 1 },
              mt: { xs: 0, sm: -3 },
            }}
          >
            <FormControlLabel
              sx={{
                pt: { xs: 1, sm: 3 },
                '& .MuiTypography-root': {
                  fontSize: { xs: '14px', sm: '18px', md: '20px' }
                }
              }}
              label="Last Week"
              control={
                <Checkbox
                  checked={orderTimeChecked[0]}
                  onChange={() => handleOrderTimeChange(0)}
                />
              }
            />
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {
                  fontSize: { xs: '14px', sm: '18px', md: '20px' }
                }
              }}
              label="Last 30 days"
              control={
                <Checkbox
                  checked={orderTimeChecked[1]}
                  onChange={() => handleOrderTimeChange(1)}
                />
              }
            />
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {
                  fontSize: { xs: '14px', sm: '18px', md: '20px' }
                }
              }}
              label="Older"
              control={
                <Checkbox
                  checked={orderTimeChecked[2]}
                  onChange={() => handleOrderTimeChange(2)}
                />
              }
            />
          </Box>

        </Paper>
      </Grid>

      {/* Orders Section */}
      <Grid className='order_items' item xs={12} md={9} sx={{ width: '80%', paddingLeft: '3%' }}>
        <Typography variant="h6" color="inherit" component="div" style={{ paddingBottom: '20px',display:'flex',alignItems:'center',gap:'10px' }}>
       <Link to='/'> <IoArrowBackCircleOutline  id='back_order'/></Link> 
        Orders
        </Typography>
        <div>
          {Array.isArray(props.order.view_all_order) &&
            props.order.view_all_order.length > 0 ? (
            props.order.view_all_order.map((item, index) => (
              item.productDetails && item.productDetails[0] && (
                <Paper
                  key={item._id}
                  sx={{
                    margin: { xs: '10px', md: 'auto' },
                    marginBottom: { xs: 1, md: 2 },
                    flexGrow: 1,
                    border: '1px solid rgba(11, 11, 11, 0.30)',
                    backgroundColor: 'white',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    padding: { xs: '10px', md: '20px' },
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid  className='inside_papaer_order' container spacing={2} sx={{
                    alignItems: 'center', display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                    <Grid
                      item
                      className='image_box'
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
                        alignItems: 'center'
                      }}
                    >
                      <ButtonBase
                        sx={{
                          width: { xs: 100, sm: 100, md: 128 },
                          height: { xs: 80, sm: 100, md: 128 }
                        }}
                      >
                        <div className='cntr'>
                          <img
                            alt="complex"
                            src={item.productDetails[0].productDetails.images}
                            style={{ width: '100%', height: '100%' }}
                          />
                        </div>
                      </ButtonBase>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        sx={{
                          fontSize: { xs: '14px', sm: '16px', md: '18px' },
                          color: '#0B0B0B',
                          fontWeight: '400',
                        }}
                      >
                        {item.productDetails[0].productDetails.name}
                      </Typography>

                      <Grid item sx={{ display: 'flex', justifyContent: 'space-between', mt: { xs: 1, sm: 2 } }}>
                        <Typography sx={{
                          fontSize: { xs: '12px', sm: '14px', md: '15px' },
                          color: 'rgba(11, 11, 11, 0.60)',
                          fontWeight: '700',
                        }}>Colour: Violet & Pink</Typography>
                        <Typography sx={{
                          fontSize: { xs: '12px', sm: '14px', md: '15px' },
                          color: 'rgba(11, 11, 11, 0.60)',
                          fontWeight: '400',
                        }}>Need Help?</Typography>
                      </Grid>

                    </Grid>
                    <Grid className='order_price' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',paddingLeft:'40px' }}>
                      <Typography sx={{
                        fontSize: { xs: '14px', sm: '16px', md: '18px' },
                        color: '#0B0B0B',
                        fontWeight: '500',
                        marginTop: { xs: '10px', sm: '15px', md: '20px' },
                      }}>
                        ₹ {item.productDetails[0].productDetails.regularPrice}
                      </Typography>

                    </Grid>
                    <Grid item xs={12} sm sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px', xl: '20px' },
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          mt: { xs: 1, sm: 2 },
                        }}
                      >
                        <FaRegCircle style={{ color: 'green' }} />
                        Delivery expected by Jan 23
                      </Typography>
                      <Typography sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px' }, mt: { xs: 1, sm: 2 } }}>Your Order has been Placed.</Typography>
                      <Button
                        variant="contained"
                        sx={{
                          width: { xs: '80%', sm: '70%', md: '40%' },
                          marginTop: '10px',
                          background: '#FF70A6',
                          borderRadius: '40px',
                          fontSize: { xs: '12px', sm: '14px', md: '14px' },
                          padding: { xs: '8px', sm: '10px', md: '10px' }
                        }}
                      >
                        Return
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              )
            ))
          ) : (
            <Typography>No items found.</Typography>
          )
          }
        </div>

      </Grid>
    </div>

  );
};

export default Orders;
