import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../FocusonPage/FocusOnPage.css";
 
const FocusOnPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
 
  return (
    <div className="container-focuson">
      <Slider className="card-slider" {...settings}>
        {[1, 2, 3, 4, 5].map((_, index) => (
          <div key={index} className="main_focous_card">
            <div className="card w-100">
              <img
                className="card-img-top"
                src="https://s3-alpha-sig.figma.com/img/883d/e669/9404b2daa02f2bee9ee0080c7ca9268f?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EfBY0CvnWSZhWbvvDe5WIpabOh6E~NPqXdbdTz1jEDiGqT8r6apvBo3PX-L6Z5kgR9a9DwUvp4uFtOTRGcIuj2i98qYLP1P84577HPjV8--Sw~wmJ2fCgDd2GQNVXkIm3qrpBHSEIpzIv8Uq7yTupYbk25XTWOXg-GyOnGu8H7RiFv2xOAE8VyEmSFgxO0cmR6hYCGlbxH7CJ9QOhfD5vSikYem4dRWDzhQzPIBD8iS5IGo26Xo8Wa1HIMLnVi5rzgZ5VIUho~C-nBR0Z5WYLJ4YCOVsHMsRD2vXyhEBfY6DhUHUvPOP4b5R5-pfjXUvg38Acn30yBFAw~qvplRbQA__"
                alt={`Card image cap ${index + 1}`}
              />
              <div className="card-body">
                <h5 className="card-title">UPTO 30% off</h5>
                <a href="#" className="btn_focous btn-primary">
                  Shop Now
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
 
export default FocusOnPage;
 