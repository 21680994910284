/** @format */

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./MainNavbar.css";

export const MainNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <h3>
          Get 10% off on your first purchase. Use code FIRST10 at checkout
        </h3>
      </div>
      <ul className="navbar-links">
        <li>
          <NavLink
            to="/contactus"
            style={{ textDecoration: "none", color: "black" }}
          >
            Contact Us
          </NavLink>
        </li>
        <div>|</div>
        <li>
          <NavLink
            to="/aboutus"
            style={{ textDecoration: "none", color: "black" }}
          >
            About Us
          </NavLink>
        </li>
        <div>|</div>
        <li>
          <NavLink to="#" style={{ textDecoration: "none", color: "black" }}>
            KidTryz App Download
          </NavLink>
        </li>
        <div>|</div>
        <li>
          <NavLink
            to="/becomeseller"
            style={{ textDecoration: "none", color: "black" }}
          >
            Become a Seller
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
