import {  VIEW_CART_ORDERS } from "./constant";

const initial_state = {
  view_all_order: [],
  loading: false, 
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case VIEW_CART_ORDERS:
      return (state = { ...state,view_all_order: action.payload.data });
    default:
      return state;
  }
}
