
import React, { useEffect } from "react";
import stylebrand from '../../Explorebrand page/Components/explorebrand.module.css'
const ExploreBrandPage = (props) => {
  useEffect(() => {
    props.ViewBrands();
  }, []);

  return (
    <div className={stylebrand.brandsUserMainBody}>
    <div className={stylebrand.brandsUserHeadingContent}>
      <h3 className={stylebrand.brandsUserHeadingOne}>Explore Our Featured Brands</h3>
    </div>
    <div className={stylebrand.brandsUserFlex}>
      {Array.isArray(props.brand.all_view_brand) &&
        props.brand.all_view_brand.slice(0, 4).map((item) => {
          return (
            <div key={item.id} className={stylebrand.brandsUserCard}>
              <img
                src={item.logo}
                alt={item.name}
                className={stylebrand.brandsUserLogoAll}
              />
            </div>
          );
        })}
    </div>
  </div>
  );
};

export default ExploreBrandPage;
