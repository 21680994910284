import { VIEW_PROFILE } from "./constant";
import "firebase/storage";
import { setLoader, unsetLoader } from "../../common/loader/action";
import UNIVERSAL from "../../config/config";
import { set_snack_bar } from "../../common/snackbar/action";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
export function viewProfile(token) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEPROFILE + "/getUserProfile", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseJson) => {
        console.log("View Profile", responseJson);
        if (responseJson.status) {
          dispatch({
            type: VIEW_PROFILE,
            payload: {
              data: responseJson.result.data.result,
            },
          });
          dispatch(set_snack_bar(true, "Data View Successfully"));
        } else {
          dispatch({ type: VIEW_PROFILE, payload: { data: [], count: 0 } });
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(set_snack_bar(true, "An error occurred while fetching data"));
      })
      .finally(() => {
        dispatch(unsetLoader());
      });
  };
}
// editpreview, editFile, name, number, last_name, props.login.admin_id
export function updateprofile(
  editPreview,
  attachment,
  first_name,
  number,
  last_name,
  token
) {
  return (dispatch) => {
    dispatch(setLoader(true));

    if (attachment === null) {
      dispatch(
        Update_Userprofile(
          editPreview,
          first_name,
          number,
          last_name,
          token
        )
      );
    } else {
      const storageRef = ref(getStorage(), "/Userprofile/" + first_name + ".png");
      const uploadTask = uploadBytesResumable(storageRef, attachment);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          dispatch(setLoader(false));
          console.error("Upload failed:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((attachmentUrl) => {
            console.log(attachmentUrl, first_name, number, last_name);

            dispatch(
              Update_Userprofile(
                attachmentUrl,
                first_name,
                number,
                last_name,
                token
              )
            );
          });
        }
      );
    }
  };
}

export function Update_Userprofile(editAttachment,first_name,number,last_name,token) 
 {
  console.log( editAttachment,
    first_name,
    number,
    last_name,
    token)
  return (dispatch) => {
    dispatch(setLoader());

    return fetch(UNIVERSAL.BASEURL + "user/update/profile", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        firstName: first_name,
        lastName: last_name,
        mobileNumber: number,
        profileImage: editAttachment,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewProfile(token))
          dispatch(unsetLoader());
        } else {
          dispatch(set_snack_bar(true, "Invalid User"));
        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error("API call failed:", error);
      });
  };
}
