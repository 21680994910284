import React, { useState } from "react";
import '../styles/Filter.css';
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
const Filter = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [checked, setChecked] = useState(null);

  const handleCheckboxChange = (checkboxId) => {
    setChecked(checked === checkboxId ? null : checkboxId);
  };
  const [payoncheck, setpayoncheck] = useState(null);
  const handlepayon = (checkboxIds) => {
    setpayoncheck(payoncheck === checkboxIds ? null : checkboxIds)
  }
  const [dealdiscount, setdealdiscount] = useState(null);
  const dealdiscounthandle = (checkboxIdsdeal) => {
    setdealdiscount(dealdiscount === checkboxIdsdeal ? null : checkboxIdsdeal)
  }
  return (
    <>
      <div className="filter-block-main">
        <div className="filter-options">
          <h3 className="filter-option-heading">Sort By</h3>
          {['Popularity', 'Discount', 'Name', 'Customer Top Rated', 'New Arrivals', 'Price: High To Low', 'Price: Low To High'].map((option, index) => (
            <div key={index} className="filter-option">
              <label className="sort-by-label">
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleOptionChange}
                />
                {option}
              </label>
            </div>
          ))}
        </div>
        <div className="filter-category">
          <h3>Category</h3>
          <div className="filter-category-diaper">
            <h3 style={{ paddingLeft: "10px" }}>Baby diapers & wipes store</h3>
            <ul style={{ paddingTop: "0rem", paddingLeft: "18px" }}>
              <li>
                Baby Care
              </li>
              <li>Baby Clothing
              </li>
              <li style={{ whiteSpace: "nowrap" }}>Bedding, Furniture & Room Decor</li>
              <li>Diapering & Nappy Changing
              </li>
              <li>Feeding
              </li>
              <li>Potty Training & Step Stools</li>
            </ul>
          </div>
        </div>
        <div className="filter-deliverday" style={{ marginTop: "10px" }}>
          <h3 style={{ fontSize: "1.1rem" }}>Delivery Day</h3>
          <div className="filter-options">
            <div className="checkbox-container">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={checked === 'checkbox1'}
                  onChange={() => handleCheckboxChange('checkbox1')}
                />
                Get It Today
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={checked === 'checkbox2'}
                  onChange={() => handleCheckboxChange('checkbox2')}
                />
                Get It by Tomorrow
              </label>
            </div>
          </div>
        </div>
        <div className="filter-payon-delivery" style={{ marginTop: "10px" }}>
          <h3>Pay On Delivery</h3>
          <div className="filter-payon-container">
            <label className="filter-payon-label">
              <input
                type="checkbox"
                checked={payoncheck === 'checkbox1'}
                onChange={() => handlepayon('checkbox1')}
              />
              Eligible for Pay On Delivery
            </label>

          </div>
        </div>
        <div className="filter-dealdiscount" style={{ marginTop: "10px" }}>
          <h3>Deals & Discounts</h3>
          <div className="dealdiscount-container">
            <label className="dealdiscount-label">
              <input
                type="checkbox"
                checked={dealdiscount === 'checkbox1'}
                onChange={() => dealdiscounthandle('checkbox1')}
              />
              All Discounts
            </label>
            <label className="dealdiscount-label">
              <input
                type="checkbox"
                checked={dealdiscount === 'checkbox2'}
                onChange={() => dealdiscounthandle('checkbox2')}
              />
              Today's Deals
            </label>
          </div>
        </div>
        <div className="filter-discount-main" style={{ marginTop: "15px" }}>
          <h3>
            Discount
          </h3>
          <ul style={{ paddingLeft: "10px", paddingTop: "0.8rem" }}>
            <li>10% Off or more</li>
            <li>25% Off or more</li>
            <li>35% Off or more</li>
            <li>50% Off or more</li>
            <li>60% Off or more</li>
            <li>70% Off or more</li>
          </ul>
        </div>
        <div className="filter-Price-main" style={{ marginTop: "15px" }}>
          <h3>
            Price
          </h3>
          <ul style={{ paddingLeft: "10px", paddingTop: "0.8rem" }}>
            <li>Under ₹500
            </li>
            <li>₹500 - ₹1,000
            </li>
            <li>₹1,000 - ₹2,000</li>
            <li>₹2,000 - ₹5,000</li>
            <li>over ₹5,000</li>

          </ul>
        </div>
        <div className="filter-customer-main" style={{ marginTop: "15px" }}>
          <h3>
            Avg. Customer Review
          </h3>
          <ul style={{ paddingLeft: "10px", paddingTop: "0.8rem" }}>
            <li>4 Stars & Up & Up
            </li>
            <li>3 Stars & Up & Up
            </li>
            <li>2 Stars & Up & Up</li>
            <li>1 Star & Up & Up</li>
          </ul>
        </div>
        <div className="filter-availabity-main">
          <h3>Availability</h3>
          <div className="filter-availabity-container">
            <input
              type="checkbox"
              id="customCheckbox-availbility"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="customCheckbox" className="filter-availabity-label">
              Include Out of Stock
            </label>
          </div>
        </div>
      </div>
      <div className="filter_main">
        <button
          className="filter_toggle_button"
          onClick={toggleFilterVisibility}
        >
          Filters <IoMdArrowDropdown fontSize={20} style={{ position: "relative", top: "6px" }} />
        </button>

        <div className={`filter_popup ${isFilterVisible ? 'show_popup' : 'hide_popup'}`}>
          <div className="filter_popup_content">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <button
                className="close_popup_button"

              >
                <span className="filter-some-icon">Filter</span>
              </button>
              <IoMdClose fontSize={30} onClick={toggleFilterVisibility} />
            </div>
            <p className="filter_sortby">Sort By</p>
            <div className="filter-options">
              {['Popularity', 'Discount', 'Name', 'Customer Top Rated', 'New Arrivals', 'Price: High To Low', 'Price: Low To High'].map((option, index) => (
                <div key={index} className="filter-option">
                  <label>
                    <input
                      type="radio"
                      value={option}
                      checked={selectedOption === option}
                      onChange={handleOptionChange}
                    />
                    {option}
                  </label>
                </div>
              ))}
            </div>

            <div className="filter-category">
              <h3>Category</h3>
              <div className="filter-category-diaper">
                <h3 style={{ paddingLeft: "10px" }}>Baby diapers & wipes store</h3>
                <ul style={{ paddingTop: "0rem", paddingLeft: "18px" }}>
                  <li>
                    Baby Care
                  </li>
                  <li>Baby Clothing
                  </li>
                  <li style={{ whiteSpace: "nowrap" }}>Bedding, Furniture & Room Decor</li>
                  <li>Diapering & Nappy Changing
                  </li>
                  <li>Feeding
                  </li>
                  <li>Potty Training & Step Stools</li>
                </ul>
              </div>
            </div>
            <div className="filter-deliverday" style={{ marginTop: "10px" }}>
              <h3 style={{ fontSize: "1.1rem" }}>Delivery Day</h3>
              <div className="filter-options">
                <div className="checkbox-container">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={checked === 'checkbox1'}
                      onChange={() => handleCheckboxChange('checkbox1')}
                    />
                    Get It Today
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={checked === 'checkbox2'}
                      onChange={() => handleCheckboxChange('checkbox2')}
                    />
                    Get It by Tomorrow
                  </label>
                </div>
              </div>
            </div>
            <div className="filter-payon-delivery" style={{ marginTop: "10px" }}>
              <h3>Pay On Delivery</h3>
              <div className="filter-payon-container">
                <label className="filter-payon-label">
                  <input
                    type="checkbox"
                    checked={payoncheck === 'checkbox1'}
                    onChange={() => handlepayon('checkbox1')}
                  />
                  Eligible for Pay On Delivery
                </label>

              </div>
            </div>
            <div className="filter-dealdiscount" style={{ marginTop: "10px" }}>
              <h3>Deals & Discounts</h3>
              <div className="dealdiscount-container">
                <label className="dealdiscount-label">
                  <input
                    type="checkbox"
                    checked={dealdiscount === 'checkbox1'}
                    onChange={() => dealdiscounthandle('checkbox1')}
                  />
                  All Discounts
                </label>
                <label className="dealdiscount-label">
                  <input
                    type="checkbox"
                    checked={dealdiscount === 'checkbox2'}
                    onChange={() => dealdiscounthandle('checkbox2')}
                  />
                  Today's Deals
                </label>
              </div>
            </div>
            <div className="filter-discount-main" style={{ marginTop: "15px" }}>
              <h3>
                Discount
              </h3>
              <ul style={{ paddingLeft: "10px", paddingTop: "0.8rem" }}>
                <li>10% Off or more</li>
                <li>25% Off or more</li>
                <li>35% Off or more</li>
                <li>50% Off or more</li>
                <li>60% Off or more</li>
                <li>70% Off or more</li>
              </ul>
            </div>
            <div className="filter-Price-main" style={{ marginTop: "15px" }}>
              <h3>
                Price
              </h3>
              <ul style={{ paddingLeft: "10px", paddingTop: "0.8rem" }}>
                <li>Under ₹500
                </li>
                <li>₹500 - ₹1,000
                </li>
                <li>₹1,000 - ₹2,000</li>
                <li>₹2,000 - ₹5,000</li>
                <li>over ₹5,000</li>

              </ul>
            </div>
            <div className="filter-customer-main" style={{ marginTop: "15px" }}>
              <h3>
                Avg. Customer Review
              </h3>
              <ul style={{ paddingLeft: "10px", paddingTop: "0.8rem" }}>
                <li>4 Stars & Up & Up
                </li>
                <li>3 Stars & Up & Up
                </li>
                <li>2 Stars & Up & Up</li>
                <li>1 Star & Up & Up</li>
              </ul>
            </div>
            <div className="filter-availabity-main">
              <h3>Availability</h3>
              <div className="filter-availabity-container">
                <input
                  type="checkbox"
                  id="customCheckbox-availbility"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="customCheckbox" className="filter-availabity-label">
                  Include Out of Stock
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
