import { Button, Grid, Typography, Paper } from "@mui/material";
import { Divider, Checkbox, MenuItem, TextField, Popover } from "@mui/material";
import img1 from "../../Assets/feedingBotel.png";
import "./orderSummary.css";
import React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { IoStarSharp } from "react-icons/io5";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { Link } from 'react-router-dom';

import StepLabel from '@mui/material/StepLabel';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
function OrderSummary() {

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#ffc107', // Star color
    },
    '& .MuiRating-iconEmpty': {
      color: '#e0e0e0', // Empty star color
    },
    '& .MuiRating-root:hover': {
      opacity: 1, // No hover effect
    },
  });
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const steps = [
    'Address',
    'Order Summary',
    'Payment',
  ];
  const [value, setValue] = React.useState(2);

  const PinkButton = styled(Button)({
    backgroundColor: "#FF70A6",
    color: "#fff", // Text color
    "&:hover": {
      backgroundColor: "#FF70A6", // Maintain the same color on hover
    },
  });
  const itemPrice = 80; // Price per item
  const deliveryCharges = 10; // Delivery charges
  const totalPayable = itemPrice ; // Total price including delivery
  const savings = 20; // Savings on this order
  const items = [
    {
      id: 1,
      name: "LuvLap Silicone Food/Fruit Nibbler with Extra Mesh, Soft Pacifier/Feeder, Teether for Baby, Infant, Bunny Violet & Pink",
      stock: "In stock",
      color: "Colour: Violet & Pink",
      MRP: "MRP: ₹199",
      price: "₹ 154",
      discount: "23% Off",
      rating: 4.2,
      totalRatings: 9682,
      quantity: 1,
    },
  ];

  const [quantity, setQuantity] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleQuantityChange = (value) => {
    setQuantity(value);
    handleClose();
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#f1f1f1",
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: { xs: 'wrap', md: 'nowrap' },
      }}
    >
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          backgroundColor: "#f1f1f1",
          padding: '20px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            padding: { xs: '10px', sm: '20px', md: '30px', xl: '40px' }, // Responsive padding
          }}
        >
          <Stepper activeStep={1} alternativeLabel sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' }, width: { xs: '100%' } }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{
                    '& .MuiStepLabel-label': {
                      color: '#000', // Label text color
                      fontSize: { xs: '12px', sm: '14px', md: '16px', xl: '18px' }, // Responsive font size
                    },
                    '& .MuiStepIcon-root': {
                      color: '#bdbdbd', // Icon color
                      '&.Mui-active': {
                        color: 'green', // Active step icon color
                      },
                      '&.Mui-completed': {
                        color: 'green', // Completed step icon color
                      },
                      fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem', xl: '2rem' }, // Responsive icon size
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Grid
          container
          direction="column"
          style={{
            fontFamily: "Calibri",
            margin: '2% auto',
            maxWidth: '80%',
            display: 'flex',
            flexWrap: 'wrap', // Enable flex-wrap for small screens
            // justifyItems: 'left',
          }}
        >
          {items.map((item) => (
            <Paper
              key={item.id}
              elevation={3}
              className="ordersum_card"
              style={{
                padding: "20px",
                marginBottom: "2%",
                display: "flex",
                flexWrap: "wrap", // Enable wrapping inside Paper for smaller screens
                alignItems: "center",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                style={{ marginRight: "15px" }}
              />
              <img
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "150px", // Set a maximum width
                  height: "auto", // Maintain aspect ratio
                  borderRadius: "8px",
                  flexShrink: 0, // Prevent image from shrinking on smaller screens
                }}
                src={img1}
                alt={item.name}
              />

              <Grid
                container
                direction="column"
                sx={{
                  marginLeft: {
                    xs: "0px",  // Extra small screens (mobile)
                    sm: "0px",  // Small screens (tablet)
                    md: "15px",  // Medium screens (small laptop)
                    lg: "20px",  // Large screens (desktop)
                    xl: "20px",  // Extra large screens (large desktop)
                  }, flex: 1
                }} // Allow content to take up available space
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "500",
                    color: "#0B0B0B",
                    // marginBottom: "8px",
                    fontSize: {
                      xs: "16px",  // Extra small screens (mobile)
                      sm: "0.8rem",    // Small screens (tablet)
                      md: "1.0rem",  // Medium screens (small laptop)
                      lg: "1rem",  // Large screens (desktop)
                      xl: "1.2rem",  // Extra large screens (large desktop)
                    }, letterSpacing: '0px' // Adjust font size for better responsiveness
                  }}
                >
                  {item.name}</Typography>

                <Grid container spacing={1} style={{}}>
                  <Typography variant="body1" style={{ fontWeight: "400", color: "#FF70A6" }}>
                    {item.stock}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "700", color: "#0B0B0B" }}>
                    {item.color}
                  </Typography>
                </Grid>

                <Grid container alignItems="center" spacing={1}>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "0.9rem",
                      color: "#00000099",
                      textDecoration: "line-through",
                      marginRight: "10px",
                    }}
                  >
                    {item.MRP}
                  </Typography>
                  <Typography variant="h6" style={{ fontWeight: "500", color: "#0B0B0B" }}>
                    {item.price}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "400",
                      color: "#FF70A6",
                      marginLeft: "10px",
                    }}
                  >
                    {item.discount}
                  </Typography>
                </Grid>

                <Grid className="ratings_ordersummary" container alignItems="center" >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '22px', // Default font size
                      
                    }}
                  >
                    <IoStarSharp color="#FFE500" style={{ fontSize: 'inherit' }} />
                    <IoStarSharp color="#FFE500" style={{ fontSize: 'inherit' }} />
                    <IoStarSharp color="#FFE500" style={{ fontSize: 'inherit' }} />
                    <IoStarSharp color="#FFE500" style={{ fontSize: 'inherit' }} />
                    <IoStarSharp color="#bdbdbd" style={{ fontSize: 'inherit' }} />
                  </div>
                  <Typography
                    variant="body2"
                    className="total_ratings"
                    style={{
                      marginLeft: "10px",
                      color: "#0B0B0B",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    4.0
                    <span
                      style={{
                        margin: "0 10px",
                        borderLeft: "1px solid #000",
                        height: "16px",
                      }}
                    >
                      ({item.totalRatings} ratings)
                    </span>
                  </Typography>
                </Grid>

                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ marginTop: { xl: "10px", lg: '10px', xs: '20px' }, flexWrap: 'nowrap' }}
                >
                  <Grid item xs={12} sm={6} md={4} xl={3}>
                    <TextField
                      value={`Qty: ${quantity}`}
                      onClick={handleOpen}
                      InputProps={{
                        endAdornment: <ArrowDropDownIcon style={{ color: "white" }} />,
                        sx: {
                          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": { border: "none" },
                          padding: "5px 10px",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                          justifyItems: "center",
                          textAlign: "center",
                          borderRadius: "20px",
                          backgroundColor: "#FF70A6",
                          color: "white",
                        },
                      }}
                      sx={{
                        width: {
                          xs: "90%",  // Extra small screens (mobile)
                          sm: "100%",  // Small screens (tablet)
                          md: "100%",  // Medium screens (small laptop)
                          lg: "100%",  // Large screens (desktop)
                          xl: "100%",  // Extra large screens (large desktop)
                        },
                      }}
                    />
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {[...Array(10).keys()].map((num) => (
                        <MenuItem key={num + 1} onClick={() => handleQuantityChange(num + 1)}>
                          {num + 1}
                        </MenuItem>
                      ))}
                    </Popover>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Button
                      variant="text"
                      style={{
                        fontSize: "14px",
                        color: "#FF70A6",
                        textTransform: "none",
                        width: "100%", // Make button full width for smaller screens
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

          ))}

          <Grid justifyContent="center" style={{ marginTop: "2%", display: 'flex', justifyContent: 'right' }}>
            <Link to='/payment'>  <Button
              variant="contained"
              style={{
                backgroundColor: "#FF70A6",
                color: "white",
                borderRadius: "40px",
                padding: "10px 40px",
                textTransform: "none",
                fontSize: "16px",
              }}
            >
              Next
            </Button></Link>
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={3} style={{ padding: '20px', width: '100%' }}>
        <Paper
          elevation={3}
          sx={{
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: {
              xs: '90%', // Extra small screens (mobile)
              sm: '90%', // Small screens (tablet)
              md: '95%', // Medium screens (small laptop)
              lg: '100%', // Large screens (desktop)
              xl: '100%', // Extra large screens (large desktop)
            },            backgroundColor: '#fff',
          }}
        >
          <Typography variant="h6" style={{ fontWeight: '700', color: '#0B0B0B', marginBottom: '10px' }}>
            Price Details
          </Typography>
          <Divider style={{ marginBottom: '15px' }} />

          <Grid container justifyContent="space-between" style={{ marginBottom: '10px' }}>
            <Typography variant="body1" style={{ fontWeight: '500', color: '#0B0B0B' }}>
              Price (1 item)
            </Typography>
            <Typography variant="body1" style={{ fontWeight: '500', color: '#FF70A6' }}>
              ${itemPrice.toFixed(2)}
            </Typography>
          </Grid>

          <Grid container justifyContent="space-between" style={{ marginBottom: '10px' }}>
            <Typography variant="body1" style={{ fontWeight: '500', color: '#0B0B0B' }}>
              Delivery Charges
            </Typography>
            <Typography variant="body1" style={{ fontWeight: '500', color: '#FF70A6' }}>
              <del>${deliveryCharges.toFixed(2)} </del><span style={{color:'#5fca4d'}}> free</span>
            </Typography>
          </Grid>

          <Divider style={{ margin: '10px 0' }} />

          <Grid container justifyContent="space-between" style={{ marginBottom: '10px' }}>
            <Typography variant="h6" style={{ fontWeight: '700', color: '#0B0B0B' }}>
              Total Payable
            </Typography>
            <Typography variant="h6" style={{ fontWeight: '700', color: '#FF70A6' }}>
              ${totalPayable.toFixed(2)}
            </Typography>
          </Grid>

          <Divider style={{ margin: '10px 0' }} />

          <Grid container justifyContent="space-between">
            <Typography variant="body1" style={{ fontWeight: '500', color: '#5fca4d' }}>
              Your Savings on This Order
            </Typography>
            <Typography variant="body1" style={{ fontWeight: '500', color: '#FF70A6' }}>
              ${savings.toFixed(2)}
            </Typography>
          </Grid>
        </Paper>
      </Grid>

    </Grid>
  );
}
export default OrderSummary;
