
import React, { useEffect } from "react";
import "../Components/DealsOnCare.css";
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import "owl.carousel";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Box
} from "@mui/material";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const DealsOnCare = (props) => {
  const NextArrow = ({ onClick }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent:'center',
          alignItems:'center',
          background: "white",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          zIndex: 1,
          right: "0px",
          position: "absolute", // Ensure it's positioned properly
          top: "50%", // Align vertically center
          transform: "translateY(-50%)", // Correct for the element's height
          cursor: "pointer",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow added here

        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon style={{ color: "#000" }} />
      </div>
    );
  };

  // Custom Prev Arrow
  const PrevArrow = ({ onClick }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent:'center',
          alignItems:'center',
          background: "white",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow added here
          zIndex: 1,
          left: "4px",
          position: "absolute", // Ensure it's positioned properly
          top: "50%", // Align vertically center
          transform: "translateY(-50%)", // Correct for the element's height
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <ArrowBackIosIcon style={{ color: "#000" }} />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1300,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 4, 
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    props.ViewAllProducts(props.login.admin_id);
  }, []);
  // console.log(props.products.all_product_view);
  const handleLoginPopup = () => {
    alert("Please log in to add to bag");
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="dealcards">
      <div className="Deal_head">
        <h2>Deals on Baby Care</h2>
      </div>
      <div className="carousel-container">
      <Slider {...settings}>
        {Array.isArray(props.products.all_product_view) &&
          props.products.all_product_view.map((items) => {
            return (
              <Link to="/products/:_id" key={items.id} onClick={scrollToTop}>
                <Card
                  sx={{
                    maxWidth: 305,
                    borderRadius: "15px 15px 10px 10px",
                    border: "1px solid rgba(11, 11, 11, 0.30)",
                    background: "#fff",
                    margin: "0 auto", 
                    
                  }}
                  className="deals-card-material"
                >
                  <div className="slide-imagesection">
                    <div>
                      <CardMedia
                        component="img"
                        // image={items.images[1]}
                        image={"https://media.istockphoto.com/id/487770577/photo/makeup-set-on-table-front-view.jpg?s=612x612&w=0&k=20&c=IS_ZuHCF3N66jhDMwt2s7J_PGWABlpv2ISEAwpJ4JKU="}
                        alt={items.images[0]?.split('/').pop().split('.')[0]}
                        sx={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                  <CardActions sx={{ backgroundColor: '#f5f5f' }}>
                    <Button
                      size="small"
                      sx={{
                        backgroundColor: "red",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "red",
                          color: "white",
                        },
                      }}
                    >
                      Up to {items.discountPercentage}% off
                    </Button>
                    <Button size="small" sx={{ color: "red" }}>
                      Deals of the Day
                    </Button>
                  </CardActions>
                  <CardContent
                    sx={{
                      textAlign: "left",
                      marginTop: "-1.5rem",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textAlign: "left" }}
                    >
                      {typeof items.description === 'string' && items.description.length > 30
        ? `${items.description.slice(0, 36)}...`
        : items.description || 'No description available.'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            );
          })}
      </Slider>
    </div>


    </div>



  );
};
export default DealsOnCare;
