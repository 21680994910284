import {  ADD_WISHLIST, VIEW_WISHLIST } from "./constant";

const initial_state = {
  all_view_wishlist: [],
  loading: false, 
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case VIEW_WISHLIST:
      return (state = { ...state, all_view_wishlist: action.payload.data });
    default:
      return state;
    case ADD_WISHLIST:
      return {
        ...state,
        loading: true, 
      };
   
  }
}