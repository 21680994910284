import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import categorystyle from "../styles/Category.module.css";
import "../styles/star.css";
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
export const FilterCard = (props) => {
  useEffect(() => {
    props.viewCatergoriesById(props.categories.cat_id);
    return () => { };
  }, []);

  const [rating, setRating] = useState(0);

  const handleRatingClick = (value) => {
    setRating(value);
  };
  const filledStarsCount = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  return (
    <>
      <div className={categorystyle.filter_page_card_main_body}>
        {Array.isArray(props.allfilterproduct.all_cat_praduct) && props.allfilterproduct.all_cat_praduct.length > 0 ? (
          props.allfilterproduct.all_cat_praduct.map((carddata) => (
            <div key={carddata.id} className={categorystyle.age_filter_card_all}>
              <div className={categorystyle.age_image_container}>
                <Link
                  to="/productsallview"
                  onClick={() => {
                    props.viewDescription(carddata);
                  }}
                >
                  <img
                    src={carddata.images}
                    alt=""
                    className={categorystyle.age_card_image_main}
                  />
                </Link>
              </div>
              <div className={categorystyle.age_card_one_row_line}>
                <p className={categorystyle.age_card_title}>
                  {carddata.name.length > 39
                    ? carddata.name.substring(0, 39) + "..."
                    : carddata.name}
                </p>
                <div className={categorystyle.mrp_flex_ages}>
                  <p className="filter_card_mrp_para_one">
                    MRP:
                    <del> ₹{carddata.regularPrice}</del>
                  </p>
                  <p className="filter_card_mrp_para_two">
                    ₹
                    {parseInt(carddata.regularPrice) -
                      (carddata.regularPrice * carddata.discountPercentage) /
                      100}
                  </p>
                  <div>
                    <hr className={categorystyle.line_age_straight} />
                  </div>
                  <p className="filter_card_mrp_para_three">
                    {carddata.discountPercentage} % OFF
                  </p>
                </div>
                <div className={categorystyle.age_line_star_flex}>
                  <div className="star-rating">
                    <span className="filled">★</span>
                    <span className="filled">★</span>
                    <span className="filled">★</span>
                    <span className="filled">★</span>
                    <span className="half-filled">★</span>
                  </div>
                  <div className="for-star">
                    <p>4.0</p>
                  </div>
                  <div>
                    <hr className={categorystyle.age_rating_straigth_line} />
                  </div>
                  <p className={categorystyle.quantityinstock_main}>({carddata.quantityInStock})</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={categorystyle.no_product_found}>
            <p style={{color:"#000",fontSize:"30px",fontWeight:"500"}}>No products found.</p>
          </div>
        )}
      </div>

    </>
  );
};
