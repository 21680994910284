import React, { useEffect, useState } from "react";
import productstyle from "../Components/ProductView.module.css";
import { IoStarSharp } from "react-icons/io5";
import "../Components/ProductView.css";
import { Link, Navigate } from "react-router-dom";
import RatingReview from "./ProductViewDetails";
import Review from "./Review";
import { logDOM } from "@testing-library/react";
import ondeliverylogo from '../../../Assets/Vector.png';
import returnlogo from '../../../Assets/Return.png';
import { FaHeart, FaHeadSideCough } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import infologo from '../../../Assets/profile.png'
import heartlogo from '../../../Assets/heartblue.png';
import heartRedLogo from '../../../Assets/profile.png';
const ProductAllView = (props) => {
  const navigate = useNavigate();
  // const [isWishlisted, setIsWishlisted] = useState(false);

  const [value, setValue] = React.useState(2);
  const [productsid, setProductsId] = useState([props.descrption.viewdescription._id]);

  const [wordData, setWordData] = useState(
    props.descrption.viewdescription.images[0]
  );
  const [val, setVal] = useState(0);
  const handleClick = (index) => {

  };
  const descriptionArray = props.descrption.viewdescription.description.split('\n');



  const handleAddToCart = () => {
    props.addToCart(props.login.admin_id, productsid);

  };
  // console.log(props.descrption.viewdescription._id)

  console.log(productsid);

  return (
    <>
      <div className={productstyle.product_view_main_body}>
        {/* leftside __________image dynamic */}
        <div className={productstyle.main}>
          <img src={wordData} className={productstyle.worddata_image_body} />
          <div className={productstyle.flex_row}>
            {Array.isArray(props.descrption.viewdescription.images) &&
              props.descrption.viewdescription.images.map((data, index) => (
                <div className={productstyle.thumbnail} key={index}>
                  <img
                    className={
                      wordData.id == index ? "{productstyle.clicked}" : ""
                    }
                    src={data}
                    onClick={() => {
                      setVal(index);
                      const wordSlider = data;

                      setWordData(wordSlider);
                    }}
                    id={productstyle.product_view_image_under_body}
                  />
                </div>
              ))}
          </div>
        </div>
        {/* leftside __________image dynamic */}
        {/* rightside_________description _____start */}
        <div className={productstyle.product_view_right_description}>
          {/* rightside_________titlesection _____start */}
          {/* {Array.isArray(props.descrption.viewdescription)&&(props.descrption.viewdescription).map((items) => ( */}
          <>
            {props.login.isHome === true ? (
              <img
                // src={heartlogo}

                style={{
                  width: "40px",
                  height: "40px",
                  position: "relative",
                  top: "26px",
                  left: "-5.5rem",
                  cursor: "pointer"
                }}

              />
            ) : (
              <img
                src={heartlogo}
                style={{
                  width: "40px",
                  height: "40px",
                  position: "relative",
                  top: "26px",
                  left: "-5.5rem",
                  cursor: "pointer"
                }}
                onClick={() => {
                  props.addWishlist(props.login.admin_id, props.descrption.viewdescription._id)
                }}

              />
            )}
            {/* end */}
            <div className={productstyle.product_view_description_title}>
              <h3 className={productstyle.product_view_description_title_main}>
                {props.descrption.viewdescription.name}
              </h3>
            </div>
            <div className={productstyle.product_view_rating_section}>
              <div>
                <IoStarSharp color="#FFE500" fontSize={22} />
                <IoStarSharp color="#FFE500" fontSize={22} />
                <IoStarSharp color="#FFE500" fontSize={22} />
                <IoStarSharp color="#FFE500" fontSize={22} />
              </div>
              <h5 className={productstyle.rating_view_show}>4.2</h5>
              <div className={productstyle.product_view_rating_line}>
                <hr className={productstyle.product_view_linestar} />
              </div>
              <div>
                <h4 className={productstyle.product_view_stock_title}>
                  9,658 Ratings
                </h4>
              </div>
            </div>
            <div className={productstyle.product_view_mrp_section}>
              <h4 className={productstyle.product_view_mrp_section_del}>
                MRP:
                <del>
                  ₹{props.descrption.viewdescription.regularPrice}
                </del>
                <b style={{ color: "#000" }}>
                  {" "} ₹
                  {parseInt(props.descrption.viewdescription.regularPrice) -
                    (props.descrption.viewdescription.regularPrice *
                      props.descrption.viewdescription.discountPercentage) /
                    100}
                </b>
                {/* <br></br> */}
                <a style={{ fontSize: "14px" }}>
                </a>
              </h4>
              <div>
                <hr className={productstyle.mrp_section_view_line} />
              </div>
              <div className={productstyle.mrp_section_heading_one_body}>
                <h3 className={productstyle.mrp_section_heading_one}>
                  {props.descrption.viewdescription.discountPercentage} % OFF
                </h3>
              </div>
            </div>
            {/* <h3>Inclusive of all taxes</h3> */}
            <h3 style={{ paddingTop: "1rem" }} className={productstyle.colour_Section_main}>Colours</h3>
            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", gap: "10px", position: "relative", top: "1rem" }}>
              {props.descrption.viewdescription.colors && props.descrption.viewdescription.colors.length > 0 ? (
                props.descrption.viewdescription.colors.map((color, index) => (
                  <div key={index} style={{ width: "20px", height: "20px", backgroundColor: color, borderRadius: "50%" }} />

                ))
              ) : (
                <p style={{ fontSize: "14px", color: "#FF0000" }}>No colors found for this product</p>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", gap: "10px", position: "relative", top: "1rem" }}>
            </div>
            <div style={{ position: "relative", top: "2rem", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", gap: "40px" }} className={productstyle.logo_cashon}>
              <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", gap: "10px" }} className={productstyle.logo_cashon_title}>
                <img src={ondeliverylogo} style={{ width: "20px", height: "20px", objectFit: "contain" }} />
                <h3 style={{ fontSize: "14px", whiteSpace: "nowrap" }}>Cash on Delivery available</h3>
              </div>
              <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", gap: "10px" }} className={productstyle.logo_cashon_title}>
                <img src={returnlogo} style={{ width: "20px", height: "20px", objectFit: "contain" }} />
                <h3 style={{ fontSize: "14px", whiteSpace: "nowrap" }}>10 Days Return Policy</h3>
              </div>
            </div>
            <div id="PRODUCTPAGE_bUTTON_SECTION_MAIN_BODY">
              <div id="PRODUCTPAGE_BUTTON_SECTION_ONE">
                <div id="PRODUCTPAGE_BUTTON_SECTION_ONE_BODY">
                  {props.login.isHome === true ? (
                    <Link to="/login">
                      <button id="PRODUCTPAGE_BUTTON_SECTION_UNDER_ONE">
                        Add To Bag
                      </button>
                    </Link>
                  ) : (
                    <Link to="/shoppingcart">
                      <button
                        id="PRODUCTPAGE_BUTTON_SECTION_UNDER_ONE"
                        onClick={() => {
                          // setProductsId(props.descrption.viewdescription._id);
                          handleAddToCart();
                          // props.addToCart(props.login.admin_id,props.descrption.viewdescription._id);
                          // console.log(props.login.admin_id,props.descrption.viewdescription._id);

                        }}
                      >
                        Add To Bag
                      </button>
                    </Link>
                  )}
                </div>
                <div id="PRODUCTPAGE_BUTTON-SECTION_TWO_BODY">
                  {props.login.isHome === true ? (
                    <Link to="/login">
                      <button id="PRODUCTPAGE_BUTTON_SECTION_UNDER_TWO">
                        Buy Now
                      </button>
                    </Link>
                  ) : (
                    <Link to="/address">
                      <button id="PRODUCTPAGE_BUTTON_SECTION_UNDER_TWO">
                        Buy Now
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </>

          {/* ))} */}
          {/* rightside_________titlesection _____end */}
        </div>
        {/* rightside_________description _____end */}
      </div>
      {/* description */}
      <div>
        {/* {productData.map((product, index) => ( */}
        <div id="PRODUCTPAGE_SECRIPTION_MAIN_BODY">
          <div id="PRODUCTPAGE_DESCRIPTION_ALL">
            <div id="PRODUCTPAGE_TOP_CONTAINER">
              <h2 id="PRODUCTPAGE_HEADING_ONE">Product Description</h2>
            </div>
            <div id="PRODUCTPAGE_ALL_UL">

              <ul>
                {descriptionArray.map((item, index) => (
                  <li key={index} id="PRODUCTPAGE_ALL_LI">
                    {item}
                  </li>
                ))}
              </ul>
              {/* ))} */}
              {/* </ul> */}
            </div>
          </div>
        </div>
        {/* ))} */}
      </div>
      <div style={{ margin: "30px 0px", width: "100%", backgroundColor: "#fff", display: "flex", alignItems: "center", justifyContent: "space-around", gap: "20px" }} className={productstyle.social_internation_main}>
        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", gap: "20px" }}>
          <img src={infologo} style={{ width: "50px", height: "70px", objectFit: "cover" }} />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h3 className={productstyle.social_inter_title}>Social international</h3>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", marginTop: "10px" }}>
              <div>
                <IoStarSharp color="#FFE500" fontSize={20} />
                <IoStarSharp color="#FFE500" fontSize={20} />
                <IoStarSharp color="#FFE500" fontSize={20} />
                <IoStarSharp color="#FFE500" fontSize={20} />
              </div>
              <h3>4.3</h3>
            </div>
          </div>
        </div>
        <div className={productstyle.vendor_profile_card}>
          <div>
            <h4 style={{ textAlign: "center" }} className={productstyle.profilecard_title}>33K+</h4>
            <h4 style={{ fontWeight: "300" }} className={productstyle.profilecard_title}>Product Sold</h4>
          </div>
          <div>
            <h4 style={{ textAlign: "center" }}>84%+</h4>
            <h4 style={{ fontWeight: "300" }}>Quality Score</h4>
          </div>
          <div>
            <h4 style={{ textAlign: "center" }}>100%+</h4>
            <h4 style={{ fontWeight: "300" }}>Product Sold</h4>
          </div>
        </div>
      </div>
      <RatingReview />
      {/* <Review /> */}
    </>
  );
};
export default ProductAllView;
