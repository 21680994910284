import { Typography } from "@mui/material";
import React from "react";
import '../footer/cookies.css';
export default function cookies() {
  return (
    <div className="cookies-main-body">
      <center>
        <Typography style={{ fontSize: "30px", fontWeight: "bold" }} className="cookies-heading">
          Cookies Policy{" "}
        </Typography>
      </center>
      <div className="cokies-paragraph-main">
        <Typography style={{ fontWeight: 500 }} >
          <Typography style={{ textAlign: "justify" }}>
            Effective Date: 09/04/2024
            <br></br>
            At Kidtryz.com, we utilize cookies to enhance your browsing experience
            and provide personalized content and advertisements. Our Cookies
            Policy outlines how we use cookies, your options regarding cookies,
            and how you can manage your preferences.
          </Typography>
          <Typography style={{ textAlign: "justify" }}>
            <p style={{ fontWeight: "bold", textAlign: "left" }}>
              What are Cookies?
            </p>
            Cookies are small text files stored on your device (computer,
            smartphone, tablet) when you visit a website. These files contain
            information allowing websites to recognize your device and remember
            your preferences and actions.
          </Typography>
          <Typography style={{ textAlign: "justify" }}>
            <p style={{ fontWeight: "bold", textAlign: "left" }}>
              Types of Cookies We Use:
            </p>
            1.Essential Cookies: These cookies are vital for Kidtryz.com's
            operation, enabling basic functions like page navigation and access to
            secure areas. Without them, certain parts of the site may not work
            correctly. <br></br>
            2.Analytical/Performance Cookies: These cookies help us analyze how
            visitors use Kidtryz.com, including pages visited and interactions.
            This information aids in improving our website's performance and
            functionality. <br></br>
            3.Functionality Cookies: Functionality cookies allow Kidtryz.com to
            remember your choices (e.g., language, region) and provide
            personalized features. They can also remember changes made to text
            size, fonts, and other customizable elements. <br></br>
            4.Advertising/Targeting Cookies: These cookies deliver ads tailored to
            your interests. Placed by advertising networks or social media
            platforms with our permission, they also track the effectiveness of
            our ad campaigns.
          </Typography>
          <Typography style={{ textAlign: "justify" }}>
            <p style={{ fontWeight: "bold", textAlign: "left" }}>
              Your Cookie Choices:
            </p>
            1.By continuing to use Kidtryz.com, you consent to the use of cookies
            as outlined in this Cookies Policy. You can manage cookie preferences
            by adjusting your browser settings. Note that blocking certain cookies
            may affect your browsing experience. <br></br>
            2.Browser Settings: Most web browsers allow you to control cookies
            through their settings. Look for these options in your browser's
            "Options" or "Preferences" menu. <br></br>
            3.Opt-Out Tools: You can opt out of certain third-party cookies for
            online behavioral advertising through websites like the Digital
            Advertising Alliance (DAA) or the Network Advertising Initiative
            (NAI).
          </Typography>
          <Typography style={{ textAlign: "justify" }}>
            <p style={{ fontWeight: "bold", textAlign: "left" }}>
              Changes to this Cookies Policy:
            </p>
            We reserve the right to update or modify this Cookies Policy without
            prior notice. Any changes will be effective immediately upon posting
            the updated policy on Kidtryz.com. We encourage you to review this
            policy periodically for any updates.
          </Typography>
          <Typography style={{ textAlign: "justify" }}>
            <p style={{ fontWeight: "bold", textAlign: "left" }}>Contact Us:</p>
            If you have questions or concerns about this Cookies Policy or our use
            of cookies, please reach out to us at contact@kidtryz.com. By using
            Kidtryz.com, you agree to the use of cookies as described in this
            Cookies Policy.
          </Typography>
        </Typography>
      </div>
    </div>
  );
}
