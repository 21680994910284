import React from 'react';
import { useState } from 'react';
import { Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Card, CardContent, CardMedia, } from '@mui/material';

import profile from '../../../Assets/profile.png';
import available from '../../../Assets/avail.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { CiEdit } from "react-icons/ci";

// import 'react-phone-number-input/style.css';
//  import PhoneInput from 'react-phone-number-input';
function YourProfile(props) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        props.viewProfile(props.login.admin_id);
        return () => { };
    }, []);


    const [id, setId] = useState("");

    const [phoneNumber, setPhoneNumber] = useState('');
    const [valid, setValid] = useState(true);
    // const [imagePreview, setImagePreview] = useState('');
    // const defaultProfileImg = props.login.registration.userDetails.profileImg; // Default profile image URL
    const [name, setname] = useState('');
    const [number, setnumber] = useState('');
    const [last_name, setlastname] = useState('');

    const [gender, setgender] = useState('');
    const [editpreview, seteditPreview] = useState(null);
    const [editFile, setEditFile] = useState(null);

    const [email, setemail] = useState('');
    const [editattachment, seteditAttachment] = useState(null);
    const [attachment, setAttachment] = useState(null);

    const [password, setpassword] = useState('');
    // const handleFileeditChange = (e) => {
    //     const file = e.target.files[0];
    //     seteditAttachment(file);

    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         seteditPreview(reader.result);
    //     };
    //     if (file) {
    //         reader.readAsDataURL(file);
    //     } else {
    //         seteditPreview(null);
    //     }
    // };

    const handleClickOpen = () => {
        setOpen(true);
    };



    const handleSubmit = () => {
        setOpen(false);
    };

    // const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImagePreview(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setEditFile(file); // Set the selected file
            const reader = new FileReader();
            reader.onloadend = () => {
                seteditPreview(reader.result); // Set the preview URL
            };
            reader.readAsDataURL(file);
        } else {
            setEditFile(null); // Reset file state if no file selected
            seteditPreview(null); // Reset preview if no file selected
        }
    };
    const handleUploadClick = () => {
        document.getElementById('uploadInput').click();
    };
    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                    // minHeight: '100vh',
                    background: 'linear-gradient(135deg, #6e8efb, #a777e3)',
                    // background:'#f8f8f8', 
                    padding: {
                        xs: '2rem',  // Extra small screens
                        sm: '2.5rem',  // Small screens
                        md: '3.5rem',  // Medium screens
                        lg: '3.7rem',  // Large screens
                        xl: '4rem'   // Extra large screens
                    },
                }}
            >

                <Card
                    sx={{
                        // maxWidth: 600, 
                        width: {
                            xs: '90%',  // Extra small screens
                            sm: '90%',  // Small screens
                            md: '80%',  // Medium screens
                            lg: '70%',  // Large screens
                            xl: '70%'   // Extra large screens
                        },
                        borderRadius: '20px',
                        boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.2)',
                        padding: {
                            xs: '1.5rem',  // Extra small screens
                            sm: '1rem',  // Small screens
                            md: '1.5rem',  // Medium screens
                            lg: '2rem',  // Large screens
                            xl: '2rem'   // Extra large screens
                        },
                        backgroundColor: '#fff'
                    }}
                >
                    <Grid container justifyContent="right">
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleClickOpen();
                                setname(props.profile.all_profile.firstName);
                                setemail(props.profile.all_profile.email);
                                setnumber(props.profile.all_profile.mobileNumber);
                                seteditPreview(props.profile.all_profile.userImage);
                                setlastname(props.profile.all_profile.lastName);
                            }}
                            sx={{
                                backgroundColor: '#6e8efb',
                                color: '#fff',
                               
                                width: {
                                    xs: '40%',  // Full width on extra small screens
                                    sm: '30%', // Slightly larger on small screens
                                    md: '30%', // Medium width on medium screens
                                    lg: '20%', // Default width on large screens
                                },
                                height: {
                                    xs: '3vh',  // Larger height on extra small screens for better touch targets
                                    sm: '45px',  // Medium height on small screens
                                    md: '40px',  // Default height on medium and large screens
                                },
                                borderRadius: '20px',
                                fontSize: {
                                    xs: '0.5rem',  // Smaller font on extra small screens
                                    sm: '1rem',    // Default font size on small and medium screens
                                },
                                '&:hover': {
                                    backgroundColor: '#5e7ddb'
                                },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CiEdit style={{ fontSize: '1.1rem' }} /> &nbsp; Edit Profile
                        </Button>
                    </Grid>

                    <CardMedia>
                        <Avatar
                            alt={props.profile.all_profile.firstName}
                            src={props.profile.all_profile.userImage}
                            sx={{
                                width: 100,
                                height: 100,
                                margin: '0 auto',
                                marginBottom: '1rem',
                                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)'
                            }}
                        />
                    </CardMedia>
                    <CardContent>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginBottom: '1rem'
                            }}
                        >
                            My Profile
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    padding: {
                                        xs: '0px',  // Extra small screens
                                        sm: '0px',  // Small screens
                                        md: '16px',  // Medium screens and above
                                    },
                                }}
                            >
                                <label
                                    style={{
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        color: '#555',
                                    }}
                                >
                                    Name
                                </label>
                                <input
                                    value={props.profile.all_profile.firstName}
                                    type="text"
                                    name="name"
                                    style={{
                                        width: '100%',
                                        borderRadius: '10px',
                                        height: '2.5rem',
                                        border: '1px solid #ddd',
                                        padding: '0.5rem 1rem',
                                        fontSize: '1rem',
                                        marginBottom: '1rem',
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    padding: {
                                        xs: '0px',  // Extra small screens
                                        sm: '0px',  // Small screens
                                        md: '16px',  // Medium screens and above
                                    },
                                }}
                            >
                                <label
                                    style={{
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        color: '#555',
                                    }}
                                >
                                    Mobile Number
                                </label>
                                <input
                                    type="tel"
                                    name="mobileNumber"
                                    value={props.profile.all_profile.mobileNumber}
                                    style={{
                                        width: '100%',
                                        borderRadius: '10px',
                                        height: '2.5rem',
                                        border: '1px solid #ddd',
                                        padding: '0.5rem 1rem',
                                        fontSize: '1rem',
                                        marginBottom: '1rem',
                                    }}
                                    required
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    padding: {
                                        xs: '0px',  // Extra small screens
                                        sm: '0px',  // Small screens
                                        md: '16px',  // Medium screens and above
                                    },
                                }}
                            >
                                <label
                                    style={{
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        color: '#555',
                                    }}
                                >
                                    Email Address
                                </label>
                                <input
                                    value={props.profile.all_profile.email}
                                    type="email"
                                    name="email"
                                    style={{
                                        width: '100%',
                                        borderRadius: '10px',
                                        height: '2.5rem',
                                        border: '1px solid #ddd',
                                        padding: '0.5rem 1rem',
                                        fontSize: '1rem',
                                        marginBottom: '1rem',
                                    }}
                                />
                            </Grid>
                        </Grid>


                    </CardContent>
                </Card>
            </Grid>
            <Dialog open={open} onClose={handleSubmit}>
                <DialogTitle>Edit Profile</DialogTitle>
                <DialogContent>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {/* <Typography>Change ProfileImage</Typography> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <input
                                type="file"
                                id="uploadInput"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />

                            <label htmlFor="uploadInput" style={{ cursor: 'pointer' }}>
                                <Avatar
                                    alt={props.profile.all_profile.firstName}
                                    src={editpreview}
                                    style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                                />
                            </label>

                            <br />
                        </div>

                        <br />
                        <button
                            onClick={handleUploadClick}
                            style={{
                                width: '100%',
                                padding: '1rem',
                                fontSize: '1.1rem',
                                backgroundColor: '#38b5fe',
                                color: 'whitesmoke',
                                border: 'none',

                            }}
                        >
                            Upload New Image
                        </button>
                    </div>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => {
                            setname(e.target.value)
                        }}

                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Last Namex"
                        type="text"
                        fullWidth
                        value={last_name}
                        onChange={(e) => {
                            setlastname(e.target.value)
                        }}

                    />
                    <TextField
                        margin="dense"
                        id="number"
                        label="Number"
                        type="text"
                        fullWidth
                        value={number}
                        onChange={(e) => {
                            setnumber(e.target.value)
                        }}
                    />

                    {/* <TextField
                        margin="dense"
                        id="gender"
                        label="Gender"
                        type="text"
                        fullWidth
                        value={gender}
                        onChange={(e) => {
                            setgender(e.target.value)
                        }}
                    /> */}
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => {
                            setemail(e.target.value)
                        }}
                    />
                    {/* <TextField
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e)=>{
                            setpassword(e.target.value)
                        }}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>Cancel</Button>
                    <Button onClick={() => {
                        handleSubmit();

                        props.updateprofile(editpreview, editFile, name, number, last_name, props.login.admin_id)

                    }}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default YourProfile;
